import React from 'react';

import classNames from 'classnames';

export interface Props extends React.ComponentPropsWithoutRef<'span'> {
  color?: 'gray' | 'green' | 'red' | 'blue';
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
}

export default function Badge({ color = 'gray', onClick, className, children, ...props }: Props) {
  return (
    <span
      className={classNames(
        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset whitespace-nowrap select-none',
        {
          'cursor-pointer': onClick != null,
          'bg-gray-50 text-gray-600 ring-gray-500/10': color === 'gray',
          'bg-green-50 text-green-700 ring-green-600/20': color === 'green',
          'bg-red-50 text-red-700 ring-red-600/10': color === 'red',
          'bg-blue-50 text-blue-700 ring-blue-700/10': color === 'blue',
        },
        onClick != null && {
          'hover:bg-gray-100': color === 'gray',
          'hover:bg-green-100': color === 'green',
          'hover:bg-red-100': color === 'red',
          'hover:bg-blue-100': color === 'blue',
        },
        className,
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </span>
  );
}
