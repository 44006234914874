import { gql } from '@apollo/client';

export const CONFIRM = gql`
  mutation Confirm($input: AuthConfirmInput!) {
    authConfirm(input: $input) {
      success
    }
  }
`;

export interface Variables {
  input: {
    token: string;
  };
}

export interface Data {
  authConfirm: {
    success: true;
  };
}
