import client from '../../../lib/graphql/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../../lib/graphql/entities/query';

import { redirect } from 'react-router-dom';
import paths from '../../../paths';

import signedInOnlyLoader from '../../../lib/router/loaders/signed-in-only';
import confirmedOnlyLoader from '../../../lib/router/loaders/confirmed-only';
import onboardedOnlyLoader from '../../../lib/router/loaders/onboarded-only';

const loader = async (): Promise<Response | null> => {
  const signedInOnlyLoaderResponse = await signedInOnlyLoader();
  if (signedInOnlyLoaderResponse) { return signedInOnlyLoaderResponse; }

  const confirmedOnlyLoaderResponse = await confirmedOnlyLoader();
  if (confirmedOnlyLoaderResponse) { return confirmedOnlyLoaderResponse; }

  const onboardedOnlyLoaderResponse = await onboardedOnlyLoader();
  if (onboardedOnlyLoaderResponse) { return onboardedOnlyLoaderResponse; }

  const { data } = await client.query<Query>({ query: GET_INITIAL_DATA });
  const { previousStatus, status } = data.viewer!.billing!;

  // Don't redirect anywhere if status changed from trialing to past due.
  if (previousStatus === 'TRIALING' && status === 'PAST_DUE') { return null; }

  return redirect(paths.dashboard);
};

export default loader;
