import React, { useState, useEffect } from 'react';

import { faCloudExclamation, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import Loading from '../../../components/Loading';
import EmptyState from '../../../components/EmptyState';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';

import { Crisp } from 'crisp-sdk-web';

interface Props {
  url: string;
}

export default function WebsiteStatus({ url }: Props) {
  const [status, setStatus] = useState<'ONLINE' | 'DOWN'>();
  useEffect(() => {
    fetch(url)
      .then((response) => setStatus(response.ok ? 'ONLINE' : 'DOWN'))
      .catch(() => setStatus('DOWN'));
  }, [url]);

  // Loading state
  if (status === undefined) {
    return (
      <Loading
        text="Checking your website..."
        className="h-full"
        data-testid="website-status-loading"
      />
    );
  }

  if (status === 'DOWN') {
    return (
      <EmptyState
        topContent={(
          <Icon
            icon={faCloudExclamation}
            className="h-12 w-12 text-red-600 mx-auto"
          />
        )}
        title="Your website is down."
        text="Please contact us so we can help you get back online quickly."
        buttons={(
          <Button
            color="black"
            onClick={() => {
              Crisp.chat.open();

              // Send a message to the support team
              Crisp.message.sendText('Hi, my website is down. Please help!');

              // Respond to the user
              setTimeout(() => {
                Crisp.message.showText("Hi, I see your website is down. I'll get back to you as soon as possible.");
              }, 1000);
            }}
          >
            Contact support
          </Button>
        )}
        data-testid="website-status-down"
      />
    );
  }

  return (
    <EmptyState
      topContent={(
        <Icon
          icon={faCircleCheck}
          className="h-12 w-12 text-green-600 mx-auto"
        />
      )}
      title="Your website is online."
      text="We're actively monitoring your website to make sure it stays online."
      buttons={(
        <Button color="black" onClick={() => window.open(url, '_blank')!.focus()}>
          Open website
        </Button>
      )}
      data-testid="website-status-online"
    />
  );
}
