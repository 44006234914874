import { gql } from '@apollo/client';

export const PRICE_FIELDS = gql`
  fragment PriceFields on Price {
    id
    currency
    description
    interval
    intervalCount
    name
    plan
    unitAmount
  }
`;
