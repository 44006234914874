import { gql } from '@apollo/client';
import { BookingProvider } from '../../entities/booking-provider';

export const BOOKING_PROVIDER_CREATE_AUTHORIZATION_URL = gql`
  mutation BookingProviderCreateAuthorizationURL($input: BookingProviderCreateAuthorizationURLInput!) {
    bookingProviderCreateAuthorizationUrl(input: $input) {
      url
    }
  }
`;

export interface Variables {
  input: {
    provider: BookingProvider;
  };
}

export interface Data {
  bookingProviderCreateAuthorizationUrl: {
    url: string;
  };
}
