import { gql } from '@apollo/client';

export const SEND_CONFIRMATION_LINK = gql`
  mutation SendConfirmationLink($input: AuthSendConfirmationLinkInput!) {
    authSendConfirmationLink(input: $input) {
      success
    }
  }
`;

export interface Variables {
  input: {
    email: string;
  };
}

export interface Data {
  authSendConfirmationLink: {
    success: true;
  };
}
