import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import InputField from '../../../components/fields/InputField';

import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../../../components/Modal';
import Button from '../../../components/Button';

interface FormValues {
  currentPassword: string;
}

interface Props extends ModalProps {
  onSubmit: (input: FormValues) => void;
}

export default function CurrentPasswordModal({ onSubmit, ...props }: Props) {
  const { reset, handleSubmit, register, formState } = useForm<FormValues>({
    resolver: zodResolver(z.object({
      currentPassword: z.string().min(1, 'Please enter your current password.'),
    })),
  });

  useEffect(() => {
    reset();
  }, [props.open, reset]);

  return (
    <Modal {...props} className="max-w-xl" data-testid="current-password-modal">
      <form onSubmit={handleSubmit((form) => onSubmit(form))}>
        <div>
          <Dialog.Title className="text-base font-medium leading-6 text-gray-900">
            Let's make sure it's you
          </Dialog.Title>

          <Dialog.Description className="text-sm text-gray-500 mt-1">
            Please enter your current password to continue.
          </Dialog.Description>
        </div>

        <div className="mt-4">
          <InputField
            label="Current password"
            placeholder="Your current password"
            {...register('currentPassword')}
            type="password"
            autoComplete="current-password"
            required
            error={formState.errors.currentPassword?.message}
          />
        </div>

        <div className="mt-4 flex gap-x-2">
          <Button
            className="flex-1"
            color="white"
            onClick={props.onClose}
          >
            Cancel
          </Button>

          <Button className="flex-1" type="submit">
            Continue
          </Button>
        </div>
      </form>
    </Modal>
  );
}
