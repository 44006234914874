import { gql } from '@apollo/client';
import { USER_FIELDS } from '../../fragments/user';
import { User } from '../../entities/user';
import { Country } from '../../entities/country';

export const USER_UPDATE = gql`
  ${USER_FIELDS}

  mutation UserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...UserFields
      }
    }
  }
`;

export interface Variables {
  input: {
    email?: string | null;
    password?: string | null;
    currentPassword?: string | null;
    logo?: File | null;
    businessName?: string | null;
    subdomain?: string | null;
    country?: Country | null;
    address?: {
      line1: string;
      line2: string | null;
      city: string;
      region: string;
      postalCode: string;
      country: string;
    } | null;
    phone?: string | null;
    timeZone?: string | null;
    instagramUrl?: string | null;
    facebookUrl?: string | null;
    yelpUrl?: string | null;
    googleUrl?: string | null;
  };
}

export interface Data {
  userUpdate: {
    user: User;
  };
}
