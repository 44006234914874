import { gql } from '@apollo/client';

export const THEME_FILE_DELETE = gql`
  mutation ThemeFileDelete($input: ThemeFileDeleteInput!) {
    themeFileDelete(input: $input) {
      id
    }
  }
`;

export interface Variables {
  input: {
    id: string;
  };
}

export interface Data {
  themeFileDelete: {
    id: string;
  };
}
