import React, { useState, useEffect } from 'react';

import { gql, useQuery, useMutation } from '@apollo/client';
import { AsStoreObject } from '@apollo/client/utilities';
import {
  THEME_FILE_CREATE,
  Data as CreateData,
  Variables as CreateVariables,
} from '../../../lib/graphql/mutations/theme/file/create';
import {
  THEME_FILE_UPDATE,
  Data as UpdateData,
  Variables as UpdateVariables,
} from '../../../lib/graphql/mutations/theme/file/update';
import {
  THEME_FILE_DELETE,
  Data as DeleteData,
  Variables as DeleteVariables,
} from '../../../lib/graphql/mutations/theme/file/delete';
import { USER_FIELDS } from '../../../lib/graphql/fragments/user';
import { THEME_FIELDS } from '../../../lib/graphql/fragments/theme';
import { THEME_FILE_FIELDS } from '../../../lib/graphql/fragments/theme/file';
import { Query } from '../../../lib/graphql/entities/query';
import { ThemeFile } from '../../../lib/graphql/entities/theme/file';

import { useNavigate } from 'react-router-dom';
import paths from '../../../paths';

import { Helmet } from 'react-helmet';
import { toast } from 'react-hot-toast';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import Loading from '../../../components/Loading';
import Icon from '../../../components/Icon';
import EmptyState from '../../../components/EmptyState';
import Button from '../../../components/Button';
import Navbar from '../../../layouts/MainLayout/components/Navbar';
import SideMenu from './components/SideMenu';
import CreateFileModal from './components/CreateFileModal';
import RenameFileModal from './components/RenameFileModal';
import DeleteFileModal from './components/DeleteFileModal';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-liquid';

import { Crisp } from 'crisp-sdk-web';

import { produce } from 'immer';

export type ThemeFileFolder = 'config' | 'layouts' | 'templates' | 'sections' | 'snippets' | 'assets';

export const GET_THEME_FILES = gql`
  ${USER_FIELDS}
  ${THEME_FIELDS}
  ${THEME_FILE_FIELDS}

  query GetThemeFiles {
    viewer {
      ...UserFields
      theme {
        ...ThemeFields
        files {
          ...ThemeFileFields
        }
      }
    }
  }
`;

const ACTIVE_FILE_KEY = 'code-editor-active-file';

export default function CodeEditorPage() {
  const navigate = useNavigate();

  const [activeFile, setActiveFile] = useState<ThemeFile>();
  const [buffer, setBuffer] = useState<string>();
  useEffect(() => {
    if (activeFile == null) { return; }

    // Save active file ID in local storage
    localStorage.setItem(ACTIVE_FILE_KEY, activeFile.id);

    // Set buffer when active file changes
    if (activeFile.content != null) {
      setBuffer(activeFile.content);
    }
  }, [activeFile]);

  const [createModalState, setCreateModalState] = useState<{ open: boolean; folder?: ThemeFileFolder; }>({ open: false });
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [query, setQuery] = useState<string>();

  const { data, loading } = useQuery<Query>(GET_THEME_FILES, { fetchPolicy: 'network-only' });
  useEffect(() => {
    if (data == null) { return; }

    // Get active file from local storage
    const activeFileId = localStorage.getItem(ACTIVE_FILE_KEY);
    if (activeFileId != null) {
      const activeFile = data.viewer!.theme!.files!.find((file) => file.id === activeFileId);
      if (activeFile != null) { setActiveFile(activeFile); }
    }
  }, [data]);

  const [createThemeFile, { loading: creating }] = useMutation<CreateData, CreateVariables>(THEME_FILE_CREATE);

  const [updateThemeFile] = useMutation<UpdateData, UpdateVariables>(THEME_FILE_UPDATE);
  const [saving, setSaving] = useState(false);
  const [renaming, setRenaming] = useState(false);

  const [deleteThemeFile, { loading: deleting }] = useMutation<DeleteData, DeleteVariables>(THEME_FILE_DELETE);

  if (loading) { return <Loading className="h-full bg-[#1d1f21]" color="white" />; }

  const onCreate = async (input: CreateVariables['input']) => {
    const toastId = toast.loading('Creating file...');

    await createThemeFile({
      variables: { input },
      update: (cache, { data }) => {
        toast.success('File created.', { id: toastId });
        setCreateModalState((prev) => ({ ...prev, open: false }));

        cache.updateQuery<Query>({ query: GET_THEME_FILES }, (cachedData) => {
          return produce(cachedData!, (draft) => {
            draft.viewer!.theme!.files!.push(data!.themeFileCreate.themeFile);
          });
        });

        setActiveFile(data!.themeFileCreate.themeFile);
      },
    })
      .catch(() => toast.remove(toastId));
  };

  const onSave = async () => {
    if (buffer === activeFile!.content) { return; }

    setSaving(true);
    const toastId = toast.loading('Saving file...');

    await updateThemeFile({
      variables: {
        input: {
          id: activeFile!.id,
          content: buffer,
        },
      },
      update: () => toast.success('File saved.', { id: toastId }),
    })
      .catch(() => toast.remove(toastId))
      .finally(() => setSaving(false));
  };

  const onRename = async (key: string) => {
    if (key === activeFile!.key) { return; }

    setRenaming(true);
    const toastId = toast.loading('Renaming file...');

    await updateThemeFile({
      variables: {
        input: {
          id: activeFile!.id,
          key,
        },
      },
      update: () => {
        toast.success('File renamed.', { id: toastId });
        setRenameModalOpen(false);
      },
    })
      .catch(() => toast.remove(toastId))
      .finally(() => setRenaming(false));
  };

  const onDelete = async () => {
    const toastId = toast.loading('Deleting file...');

    await deleteThemeFile({
      variables: {
        input: { id: activeFile!.id },
      },
      update: (cache) => {
        toast.success('File deleted.', { id: toastId });
        setDeleteModalOpen(false);

        cache.evict({ id: cache.identify(activeFile as AsStoreObject<ThemeFile>) });
        setActiveFile(undefined);
      },
    })
      .catch(() => toast.remove(toastId));
  };

  const renderContent = () => {
    if (activeFile == null) {
      return (
        <EmptyState
          data-testid="no-active-file-message"
          className="px-6"
          title={<span className="text-white text-lg">Select a file to get started.</span>}
          text={(
            <span className="text-gray-400">
              Need help from one of our experts?
              Chat with us, we're happy to help!
            </span>
          )}
          buttons={(
            <Button
              onClick={() => {
                Crisp.chat.open();

                // Send a message to the support team
                Crisp.message.sendText("Hi, I'd like some help changing my website's code.");

                // Respond to the user
                setTimeout(() => {
                  Crisp.message.showText("Hi, I see you need help editing code. I'll get back to you as soon as possible.");
                }, 1000);
              }}
              color="white"
              className="text-black"
            >
              Get help from an expert
            </Button>
          )}
        />
      );
    }

    const extension = activeFile.key.split('.').pop();

    if (activeFile.fileType === 'BINARY') {
      switch (extension) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'webp':
          return (
            <div className="flex items-center justify-center h-full" data-testid="image-viewer">
              <img src={activeFile.asset!} alt={activeFile.key} className="max-w-full max-h-full" />
            </div>
          );
        case 'mp4':
        case 'webm':
          return (
            <div className="flex items-center justify-center h-full" data-testid="video-viewer">
              <video src={activeFile.asset!} controls className="max-w-full max-h-full" />
            </div>
          );
        default:
          return (
            <EmptyState
              data-testid="unhandled-binary-file-message"
              className="px-6"
              title={<span className="text-white text-lg">Can't display this file in the editor.</span>}
              text={(
                <span className="text-gray-400">
                  <a
                    href={activeFile.asset!}
                    target="_blank" rel="noreferrer"
                    className="text-white font-medium"
                  >
                    Click here
                  </a>
                  &nbsp;to open it in a new tab instead.
                </span>
              )}
            />
          );
      }
    }

    return (
      <div className="h-full" data-testid="code-editor">
        <AceEditor
          ref={(ref) => {
            if (ref == null) { return; }

            ref.editor.commands.addCommand({
              name: 'save',
              bindKey: { win: 'Ctrl-S', mac: 'Command-S' },
              exec: onSave,
            });
          }}
          name="code-editor"
          mode={(() => {
            switch (extension) {
              case 'css':
                return 'css';
              case 'js':
                return 'javascript';
              case 'json':
                return 'json';
              case 'liquid':
                return 'liquid';
              case 'svg':
                return 'html';
            }
          })()}
          value={buffer}
          onChange={setBuffer}
          width="100%"
          height="100%"
          fontSize={14}
          theme="tomorrow_night"
          showPrintMargin={false}
          setOptions={{
            tabSize: 2,
            useSoftTabs: true,
          }}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Code editor</title>
      </Helmet>

      <div className="h-full p-4 flex items-center justify-center md:hidden">
        <EmptyState
          topContent={(
            <Icon
              icon={faExclamationCircle}
              className="h-8 w-8 text-red-500 mx-auto"
            />
          )}
          title="This screen is too small."
          text="Open this page on a larger screen to use the code editor."
          buttons={(
            <Button color="black" onClick={() => navigate(paths.dashboard)}>
              Go back to Dashboard
            </Button>
          )}
        />
      </div>

      <div className="h-full w-full hidden md:block md:fixed bg-[#1d1f21]">
        <SideMenu
          files={data!.viewer!.theme!.files!}
          query={query}
          activeFile={activeFile}
          setActiveFile={setActiveFile}
          onClickCreateFile={(folder) => setCreateModalState({ open: true, folder })}
        />

        <div className="md:pl-72 flex flex-col h-full">
          <Navbar
            className="!bg-transparent border-white/5"
            inputClassName="!text-white"
            dropdownClassName="bg-white/10 text-white"
            setOpen={() => null}
            searchEnabled={true}
            searchPlaceholder="Search files..."
            onSearch={setQuery}
          />

          {activeFile != null && (
            <div className="px-4 py-2 flex justify-between items-center border-b border-white/5">
              <div className="flex items-center gap-x-4">
                <div className="font-mono text-sm text-white" data-testid="active-file-key">
                  {activeFile.key}
                </div>

                {!activeFile.protected && (
                  <Button
                    className="w-14 py-1 text-xs"
                    size="custom"
                    color="red"
                    onClick={() => setDeleteModalOpen(true)}
                    loading={deleting}
                    disabled={deleting}
                    data-testid="delete-file-button"
                  >
                    Delete
                  </Button>
                )}
              </div>

              <div className="flex gap-x-2">
                {!activeFile.protected && (
                  <Button
                    className="w-20 bg-white/90"
                    size="sm"
                    color="white"
                    onClick={() => setRenameModalOpen(true)}
                    loading={renaming}
                    disabled={renaming}
                    data-testid="rename-file-button"
                  >
                    Rename
                  </Button>
                )}

                {activeFile.fileType === 'TEXT' && (
                  <Button
                    className="w-20"
                    size="sm"
                    color="green"
                    loading={saving}
                    disabled={saving || (buffer === activeFile.content)}
                    onClick={onSave}
                    data-testid="save-file-button"
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          )}

          <main className="flex-1 h-full text-white">
            {renderContent()}
          </main>
        </div>
      </div>

      <CreateFileModal
        open={!!createModalState?.open}
        folder={createModalState?.folder}
        onClose={() => setCreateModalState((prev) => ({ ...prev, open: false }))}
        onSubmit={onCreate}
        submitting={creating}
      />

      <RenameFileModal
        open={renameModalOpen}
        file={activeFile}
        onClose={() => setRenameModalOpen(false)}
        onSubmit={onRename}
        submitting={renaming}
      />

      <DeleteFileModal
        open={deleteModalOpen}
        file={activeFile}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={onDelete}
        deleting={deleting}
      />
    </React.Fragment>
  );
}
