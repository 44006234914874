import React from 'react';

import { faLaptopBinary } from '@fortawesome/pro-regular-svg-icons';
import MainLayout from '../layouts/MainLayout';
import EmptyState from '../components/EmptyState';
import Icon from '../components/Icon';

export default function ComingSoonPage() {
  return (
    <MainLayout>
      <EmptyState
        topContent={(
          <Icon
            icon={faLaptopBinary}
            className="h-8 w-8 mx-auto"
          />
        )}
        title="Coming soon!"
        text="We're working hard to bring you this feature. We'll make an announcement when it's ready."
      />
    </MainLayout>
  );
}
