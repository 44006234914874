import React, { useState } from 'react';

import Button from '../../../components/Button';
import DeleteAccountModal from '../../../components/auth/DeleteAccountModal';

export default function DangerSection() {
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  return (
    <React.Fragment>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Danger zone</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Careful, these actions are irreversible.
          </p>
        </div>

        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-md sm:rounded-xl lg:col-span-2">
          <div className="px-4 py-6 sm:p-8 space-y-6">
            <Button color="red" onClick={() => setDeleteAccountModalOpen(true)}>
              Delete my account
            </Button>
          </div>
        </form>
      </div>

      <DeleteAccountModal
        open={deleteAccountModalOpen}
        onClose={() => setDeleteAccountModalOpen(false)}
      />
    </React.Fragment>
  );
}
