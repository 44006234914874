import React, { useEffect, useReducer, useState } from 'react';

import { useQuery } from '@apollo/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../../lib/graphql/entities/query';

import { useNavigate } from 'react-router-dom';
import paths from '../../../paths';

import { Helmet } from 'react-helmet';
import Loading from '../../../components/Loading';
import DotPattern from '../../../components/layout/DotPattern';
import Logo from '../../../components/layout/Logo';
import Button from '../../../components/Button';
import Footer from '../components/Footer';
import ConnectBookingProviderModal, { connectBookingProviderModalReducer } from '../../../components/booking-provider/ConnectBookingProviderModal';
import SkipConfirmationModal from './components/SkipConfirmationModal';

export default function BookingProviderPage() {
  const navigate = useNavigate();

  const { data, loading } = useQuery<Query>(GET_INITIAL_DATA);
  useEffect(() => {
    if (data == null) { return; }

    const { billing } = data.viewer!;

    // Redirect to "select plan" page if no subscription.
    if (billing == null) {
      return navigate(paths.onboarding.selectPlan);
    }

    // Redirect to business information page if user is not on the Pro plan.
    if (billing.plan !== 'PRO') {
      return navigate(paths.onboarding.businessInformation);
    }
  }, [data, navigate]);

  const [
    connectBookingProviderModalState,
    connectBookingProviderModalDispatch,
  ] = useReducer(connectBookingProviderModalReducer, { open: false, bookingProvider: undefined });
  const [skipConfirmationModalOpen, setSkipConfirmationModalOpen] = useState(false);

  if (loading) {
    return <Loading className="h-full" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Onboarding</title>
      </Helmet>

      <div className="flex flex-col min-h-full pt-16 lg:pt-24 px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="flex-1 relative max-w-xl mx-auto">
          <DotPattern className="absolute left-full transform translate-x-1/2" />
          <DotPattern className="absolute right-full bottom-0 transform -translate-x-1/2" />

          <Logo />

          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Connect to Square</h2>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              If you connect an account, we'll automatically sync your business information &amp; prices so your
              customers can book through Square.
            </p>
          </div>
          <div className="mt-8 space-y-2">
            <Button
              size="xl"
              fullWidth={true}
              onClick={() => connectBookingProviderModalDispatch({
                type: 'OPEN',
                bookingProvider: 'SQUARE',
              })}
            >
              <img
                className="w-6 h-6 invert mr-1.5 mb-0.5"
                src={require('../../../assets/images/icons/square.png')}
                alt=""
              />

              Connect to Square
            </Button>

            <Button
              color="white"
              size="xl"
              fullWidth={true}
              onClick={() => setSkipConfirmationModalOpen(true)}
            >
              Skip for now
            </Button>
          </div>
        </div>

        <Footer />
      </div>

      <ConnectBookingProviderModal
        open={connectBookingProviderModalState.open}
        user={data!.viewer!}
        bookingProvider={connectBookingProviderModalState.bookingProvider}
        onClose={() => connectBookingProviderModalDispatch({ type: 'CLOSE' })}
      />

      <SkipConfirmationModal
        open={skipConfirmationModalOpen}
        onClose={() => setSkipConfirmationModalOpen(false)}
        onConfirm={() => {
          setSkipConfirmationModalOpen(false);
          navigate(paths.onboarding.businessInformation);
        }}
      />
    </React.Fragment>
  );
}
