import React from 'react';

import { useNavigate } from 'react-router-dom';
import paths from '../../../../../paths';

import TaskCell from '../TaskCell';

interface Props {
  completed: boolean;
}

export default function AddServices({ completed }: Props) {
  const navigate = useNavigate();

  return (
    <TaskCell
      title="Add your prices"
      text="Start adding your prices so your customers can view them on your website."
      completed={completed}
      onClick={() => navigate(paths.services.index)}
      data-testid="add-services-task-cell"
    />
  );
}
