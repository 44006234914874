import { OpenPeriod, Day } from '../entities/open-period';

export default class OpenPeriodDecorator {
  private openPeriod: OpenPeriod;

  constructor(openPeriod: OpenPeriod) {
    this.openPeriod = openPeriod;
  }

  get day() {
    return OpenPeriodDecorator.day(this.openPeriod.day);
  }

  static day(day: Day) {
    switch (day) {
      case 'SUNDAY':
        return 'Sunday';
      case 'MONDAY':
        return 'Monday';
      case 'TUESDAY':
        return 'Tuesday';
      case 'WEDNESDAY':
        return 'Wednesday';
      case 'THURSDAY':
        return 'Thursday';
      case 'FRIDAY':
        return 'Friday';
      case 'SATURDAY':
        return 'Saturday';
    }
  }

  get timeRange() {
    return `${this.openPeriod.startFormatted} - ${this.openPeriod.endFormatted}`;
  }
}
