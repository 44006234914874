import { gql } from '@apollo/client';

export const UNSPLASH_PHOTO_TRACK_DOWNLOAD = gql`
  mutation UnsplashPhotoTrackDownload($input: UnsplashPhotoTrackDownloadInput!) {
    unsplashPhotoTrackDownload(input: $input) {
      id
    }
  }
`;

export interface Variables {
  input: {
    id: string;
  };
}

export interface Data {
  unsplashPhotoTrackDownload: {
    id: string;
  };
}
