import React, { useState, useEffect, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import {
  USER_UPDATE,
  Data as UpdateData,
  Variables as UpdateVariables,
} from '../../../../../lib/graphql/mutations/user/update';
import {
  DOMAIN_CREATE,
  Data as DomainCreateData,
  Variables as DomainCreateVariables,
} from '../../../../../lib/graphql/mutations/domain/create';
import { GET_CUSTOMIZATION_DATA } from '../../CustomizePage';
import { User } from '../../../../../lib/graphql/entities/user';
import { Query } from '../../../../../lib/graphql/entities/query';

import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { ignoreEmpty } from '../../../../../lib/utils/form';
import FieldContainer from '../../../../../components/fields/FieldContainer';
import InputField from '../../../../../components/fields/InputField';
import Button from '../../../../../components/Button';
import DomainCell from './components/DomainCell';
import DomainModal from './components/DomainModal';
import ProRequiredModal from '../../../../../layouts/MainLayout/components/ProRequiredModal';

import { produce } from 'immer';
import { MYPRICES_SITE_DOMAIN } from '../../../../../lib/constants';

interface Props {
  user: User;
}

interface FormValues {
  subdomain: string;
  domain?: string;
}

export default function DomainSection({ user }: Props) {
  const [domainModalOpen, setDomainModalOpen] = useState(false);
  const [proRequiredModalOpen, setProRequiredModalOpen] = useState(false);

  const [updateUser] = useMutation<UpdateData, UpdateVariables>(USER_UPDATE, {
    update: () => {
      toast.success('Domain settings updated.');
    },
  });

  const [createDomain] = useMutation<DomainCreateData, DomainCreateVariables>(DOMAIN_CREATE, {
    update: (cache, { data }) => {
      cache.updateQuery<Query>({ query: GET_CUSTOMIZATION_DATA }, (cachedData) => {
        return produce(cachedData!, (draft) => {
          draft.viewer!.domain = data!.domainCreate.domain;
        });
      });

      setDomainModalOpen(true);
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(z.object({
      subdomain: z.string().min(1, { message: 'Please enter a valid subdomain.' }),
      domain: z.string().transform(ignoreEmpty),
    })),
  });

  const resetFields = useCallback(() => {
    const { subdomain } = user;
    form.reset({
      subdomain: subdomain!,
      domain: '',
    });
  }, [form, user]);

  useEffect(() => {
    resetFields();
  }, [form.formState.isSubmitSuccessful, resetFields]);

  const onSubmit = async (input: FormValues) => {
    if (input.domain) {
      await createDomain({
        variables: {
          input: {
            name: input.domain,
          },
        },
      });
    }

    await updateUser({
      variables: {
        input: {
          subdomain: input.subdomain,
        },
      },
    });
  };

  return (
    <React.Fragment>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Domain</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Customize your <span className="text-black font-medium">{MYPRICES_SITE_DOMAIN}</span> domain, or add your own custom domain.
          </p>
        </div>

        <form onSubmit={form.handleSubmit(onSubmit)} className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-md sm:rounded-xl lg:col-span-2">
          <div className="px-4 py-6 sm:p-8 space-y-6">
            <InputField
              label="MyPrices subdomain"
              placeholder="my-business"
              {...form.register('subdomain')}
              type="text"
              required
              error={form.formState.errors.subdomain?.message}
              className="pr-32"
              rightAddon={<span className="text-gray-500 text-sm">.{MYPRICES_SITE_DOMAIN}</span>}
              help={(
                <span data-testid="subdomain-input-help">
                  Your {MYPRICES_SITE_DOMAIN} link {form.formState.dirtyFields.subdomain ? 'will be' : 'is'}&nbsp;
                  <span
                    className="text-black underline font-medium cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(`${form.watch('subdomain')}.${MYPRICES_SITE_DOMAIN}`)
                        .then(() => {
                          toast.success('Copied to clipboard.');
                        });
                    }}
                    data-testid="subdomain-input-help-link"
                  >
                    {form.watch('subdomain')}.{MYPRICES_SITE_DOMAIN}
                  </span>.

                  {user.domain != null && (
                    <React.Fragment>
                      &nbsp;
                      <span>
                        It'll redirect to your custom domain&nbsp;
                        <span
                          className="text-black underline font-medium cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(user.domain!.name)
                              .then(() => {
                                toast.success('Copied to clipboard.');
                              });
                          }}
                          data-testid="subdomain-input-help-domain-link"
                        >
                          {user.domain.name}
                        </span>.
                      </span>
                    </React.Fragment>
                  )}
                </span>
              )}
            />

            {(() => {
              if (user.domain) {
                return (
                  <FieldContainer label="Custom domain">
                    <div className="shadow-sm ring-1 ring-gray-900/5 rounded-md sm:rounded-xl overflow-hidden">
                      <DomainCell
                        domain={user.domain}
                        onClick={() => setDomainModalOpen(true)}
                      />
                    </div>
                  </FieldContainer>
                );
              }

              return (
                <InputField
                  label="Custom domain"
                  placeholder="example.com"
                  {...form.register('domain')}
                  type="text"
                  error={form.formState.errors.domain?.message}
                  disabled={user.billing!.plan !== 'PRO'}
                  help={(() => {
                    if (user.billing!.plan !== 'PRO') {
                      return (
                        <span>
                          Please&nbsp;
                          <span
                            className="text-black underline font-medium cursor-pointer"
                            onClick={() => {
                              setProRequiredModalOpen(true);
                            }}
                          >
                            upgrade to Pro
                          </span>
                          &nbsp;to add a custom domain.
                        </span>
                      );
                    }

                    return (
                      <span>
                        Need a domain? <a href="https://namecheap.pxf.io/756Vbg" className="text-black underline font-medium" target="_blank" rel="noreferrer">Namecheap</a> can help.
                      </span>
                    );
                  })()}
                />
              );
            })()}
          </div>
          <div className="flex items-center justify-end gap-x-2 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <Button
              color="white"
              size="sm"
              disabled={!form.formState.isDirty}
              onClick={resetFields}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="sm"
              loading={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting || !form.formState.isDirty}
            >
              Save
            </Button>
          </div>
        </form>
      </div>

      <DomainModal
        open={domainModalOpen}
        onClose={() => setDomainModalOpen(false)}
        domain={user.domain}
      />

      <ProRequiredModal
        open={proRequiredModalOpen}
        onClose={() => setProRequiredModalOpen(false)}
        description="Custom domains are only available on the Pro plan."
      />
    </React.Fragment>
  );
}
