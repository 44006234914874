import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Query } from '../../lib/graphql/entities/query';
import { USER_FIELDS } from '../../lib/graphql/fragments/user';
import { OPEN_PERIOD_FIELDS } from '../../lib/graphql/fragments/open-period';

import MainLayout from '../../layouts/MainLayout';
import AccountSection from './components/AccountSection';
import LogoSection from './components/LogoSection';
import BusinessInformationSection from './components/BusinessInformationSection';
import ExternalLinksSection from './components/ExternalLinksSection';
import OpenPeriodsSection from './components/OpenPeriodsSection';
import DangerSection from './components/DangerSection';

export const GET_SETTINGS_DATA = gql`
  ${USER_FIELDS}
  ${OPEN_PERIOD_FIELDS}

  query GetSettingsData {
    viewer {
      ...UserFields
      openPeriods {
        ...OpenPeriodFields
      }
    }
  }
`;

export default function SettingsPage() {
  const { data, loading } = useQuery<Query>(GET_SETTINGS_DATA, {
    fetchPolicy: 'network-only',
  });

  return (
    <MainLayout
      title="Settings"
      text="Manage everything related to your account & business information here."
      loading={loading}
    >
      <div className="space-y-10 divide-y divide-gray-900/10">
        <AccountSection user={data?.viewer!} />
        <LogoSection user={data?.viewer!} />
        <BusinessInformationSection user={data?.viewer!} />
        <ExternalLinksSection user={data?.viewer!} />
        <OpenPeriodsSection user={data?.viewer!} />
        <DangerSection />
      </div>
    </MainLayout>
  );
}
