import { gql } from '@apollo/client';
import { THEME_FILE_FIELDS } from '../../../fragments/theme/file';
import { ThemeFile, ThemeFileType } from '../../../entities/theme/file';

export const THEME_FILE_CREATE = gql`
  ${THEME_FILE_FIELDS}

  mutation ThemeFileCreate($input: ThemeFileCreateInput!) {
    themeFileCreate(input: $input) {
      themeFile {
        ...ThemeFileFields
      }
    }
  }
`;

export interface Variables {
  input: {
    key: string;
    fileType: ThemeFileType;
    content: string | null;
    asset: File | null;
  };
}

export interface Data {
  themeFileCreate: {
    themeFile: ThemeFile;
  };
}
