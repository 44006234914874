import client from '../../../lib/graphql/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../../lib/graphql/entities/query';

import { redirect } from 'react-router-dom';
import paths from '../../../paths';

import signedInOnlyLoader from '../../../lib/router/loaders/signed-in-only';
import dayjs from 'dayjs';

const loader = async (): Promise<Response | null> => {
  const signedInOnlyLoaderResponse = await signedInOnlyLoader();
  if (signedInOnlyLoaderResponse) { return signedInOnlyLoaderResponse; }

  const { data } = await client.query<Query>({ query: GET_INITIAL_DATA });

  // Redirect back to dashboard if user is confirmed
  // or not past deadline.
  if (data.viewer!.confirmed || dayjs().isBefore(data.viewer!.confirmationDeadline)) {
    return redirect(paths.dashboard);
  }

  return null;
};

export default loader;
