import React, { useEffect } from 'react';

import { Dialog, Transition, TransitionEvents } from '@headlessui/react';

import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import Icon from './Icon';

import { Crisp } from 'crisp-sdk-web';

export interface Props extends TransitionEvents {
  open: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
}

export default function SlideOver({ open, onClose, title, subtitle, children, ...props }: Props) {
  // Hide the Crisp chat when the slide-over is open.
  useEffect(() => {
    if (open) { return Crisp.chat.hide(); }

    Crisp.chat.show();
  }, [open]);

  return (
    <Transition.Root show={open} as={React.Fragment} appear {...props}>
      <Dialog as="div" className="z-20 fixed inset-0 overflow-hidden" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={React.Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl">
                  <div className="py-6 px-4 bg-black sm:px-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <Dialog.Title className="text-base font-medium text-white" data-testid="slide-over-title">{title}</Dialog.Title>
                        {subtitle && (
                          <div className="mt-1">
                            <Dialog.Description className="text-xs text-white">
                              {subtitle}
                            </Dialog.Description>
                          </div>
                        )}
                      </div>

                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-black rounded-md text-gray-200 hover:text-white focus:outline-none"
                          onClick={onClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <Icon icon={faXmark} className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
