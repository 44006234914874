import { gql } from '@apollo/client';

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    confirmed
    confirmationDeadline
    country
    currency
    stripeCurrency
    email
    authenticationProvider
    businessName
    logo {
      url
    }
    billing {
      plan
      status
      previousStatus
      active
    }
    bookingProvider
    subdomain
    websiteUrl
    address {
      line1
      line2
      city
      region
      postalCode
      country
      latitude
      longitude
      formatted
    }
    phone
    timeZone
    instagramUrl
    facebookUrl
    yelpUrl
    googleUrl
    crispEmailSignature
  }
`;
