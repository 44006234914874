import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { AsStoreObject } from '@apollo/client/utilities';
import {
  SERVICE_UPDATE,
  Variables as UpdateVariables,
  Data as UpdateData,
} from '../../../lib/graphql/mutations/service/update';
import {
  SERVICE_DELETE,
  Variables as DeleteVariables,
  Data as DeleteData,
} from '../../../lib/graphql/mutations/service/delete';
import { User } from '../../../lib/graphql/entities/user';
import { Service, PricingType } from '../../../lib/graphql/entities/service';

import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { cleanEmpty } from '../../../lib/utils/form';
import InputField from '../../../components/fields/InputField';
import TextAreaField from '../../../components/fields/TextAreaField';
import SelectField from '../../../components/fields/SelectField';
import FieldContainer from '../../../components/fields/FieldContainer';

import toast from 'react-hot-toast';
import SlideOverForm from '../../../components/SlideOverForm';
import Button from '../../../components/Button';

import {
  faArrowRotateLeft, faMoneyBillsSimple, faCircleInfo,
  faArrowDown, faArrowUp,
} from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../../components/Icon';

import _ from 'lodash';
import { produce } from 'immer';
import pluralize from 'pluralize';
import classNames from 'classnames';
import { currencyFromCode } from '../../../lib/utils/currency';

interface FormValues {
  name: string;
  description: string;
  variations: Array<{
    id?: string;
    name: string;
    duration: {
      hours: number;
      minutes: number;
    };
    price: {
      type: PricingType;
      amount?: number | null;
      displayAs?: string | null;
    };

    delete?: boolean;
  }>;
}

interface Props {
  user: User;
  service: Service | null;
  open: boolean;
  onClose: () => void;
}

export default function UpdateServiceSlideOverForm({ user, service, ...props }: Props) {
  const [updateService] = useMutation<UpdateData, UpdateVariables>(SERVICE_UPDATE);
  const [deleteService, { loading: deleting }] = useMutation<DeleteData, DeleteVariables>(SERVICE_DELETE);

  const { control, register, watch, handleSubmit, formState, reset } = useForm<FormValues>({
    resolver: zodResolver(z.object({
      name: z.string().min(1, { message: 'Please enter a name for this price.' }),
      description: z.string().nullable(),
      variations: z.array(
        z.object({
          id: z.string().optional(),
          name: z.string().min(1, { message: 'Please enter a name for this variation.' }),
          duration: z.object({
            hours: z.coerce.number(),
            minutes: z.coerce.number(),
          }).refine((duration) => duration.hours > 0 || duration.minutes > 0, 'Please enter a valid duration for this variation.'),
          price: z.object({
            type: z.string(),
            amount: z.coerce.number().nullish(),
            displayAs: z.string().nullish().transform(cleanEmpty),
          }).superRefine((price, context) => {
            switch (price.type as PricingType) {
              case 'FIXED':
                if (price.amount == null || price.amount < 0) {
                  return context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Please enter a valid price for this variation.',
                  });
                }

                break;
              case 'VARIABLE':
                if (price.displayAs == null) {
                  return context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Please enter a valid price display for this variation.',
                    path: ['displayAs'],
                  });
                }

                break;
            }
          }),
          delete: z.boolean().optional(),
        }),
      ).refine((variations) => variations.filter((v) => v.delete !== true).length > 0),
    })),
  });

  const variationFields = useFieldArray({ control, name: 'variations', keyName: 'key' });

  // Set form values on open
  useEffect(() => {
    if (!props.open) { return; }

    reset({
      name: service!.name,
      description: service!.description,
      variations: service!.variations.map((variation) => ({
        id: variation.id,
        name: variation.name,
        duration: (() => {
          const totalMinutes = variation.duration / 1_000 / 60;
          const totalHours = Math.floor(totalMinutes / 60);

          return {
            hours: totalHours,
            minutes: totalMinutes - (totalHours * 60),
          };
        })(),
        price: {
          type: variation.price.type,
          amount: variation.price.amount && variation.price.amount / 100,
          displayAs: variation.price.displayAs,
        },
      })),
    });
  }, [props.open, service, reset]);

  const onClickAddVariation = () => variationFields.append({
    name: '',
    duration: {
      hours: 0,
      minutes: 0,
    },
    price: {
      type: 'FIXED',
      amount: null,
      displayAs: null,
    },
  });

  const onSubmit = async (input: FormValues) => {
    // Clean up input
    input = produce(input, (draft) => {
      draft.variations = draft.variations.map((variation) => {
        switch (variation.price.type) {
          case 'VARIABLE':
            // Remove price.amount from any variable variation.
            return produce(variation, (draft) => { draft.price.amount = null; });
          default:
            return variation;
        }
      });
    });

    await updateService({
      variables: {
        input: {
          id: service!.id,
          name: input.name,
          description: input.description,
          variations: input.variations.map((variation, index) => ({
            id: variation.id,
            name: variation.name,
            duration: (variation.duration.hours * 60 * 60 * 1_000) + (variation.duration.minutes * 60 * 1_000),
            price: {
              type: variation.price.type,
              amount: variation.price.amount && variation.price.amount * 100,
              displayAs: variation.price.displayAs,
            },
            position: index + 1,
            delete: variation.delete,
          })),
        },
      },
      update: () => {
        props.onClose();
        toast.success('Updated this price.');
      },
    });
  };

  if (service == null) { return null; }
  return (
    <SlideOverForm
      {...props}
      title={`Update ${service.name}`}
      subtitle="This price will show up on your website."
      onSubmit={handleSubmit(onSubmit)}
      buttonsClassName="justify-between"
      buttons={(
        <React.Fragment>
          <div>
            <Button
              color="red"
              onClick={() => deleteService({
                variables: {
                  input: { id: service.id },
                },
                update: (cache) => {
                  cache.evict({ id: cache.identify(service as AsStoreObject<Service>) });

                  props.onClose();
                  toast.success('Deleted this price.');
                },
              })}
              loading={deleting}
              disabled={deleting}
              data-testid="delete-button"
            >
              Delete
            </Button>
          </div>

          <div className="flex space-x-2">
            <Button color="white" onClick={props.onClose} data-testid="close-button">Cancel</Button>
            <Button color="black" type="submit" loading={formState.isSubmitting} disabled={formState.isSubmitting} data-testid="submit-button">
              Update price
            </Button>
          </div>
        </React.Fragment>
      )}
    >
      <div className="space-y-6">
        <InputField
          label="Name"
          placeholder="Really awesome service"
          {...register('name')}
          type="text"
          required
          error={formState.errors.name?.message}
          data-testid="name-input"
        />

        <TextAreaField
          label="Description"
          placeholder="Describe your really awesome service..."
          rows={4}
          {...register('description')}
          error={formState.errors.description?.message}
          data-testid="description-input"
        />

        <FieldContainer
          label="Variations"
          error={(() => {
            if (variationFields.fields.filter((v) => v.delete !== true).length === 0) {
              return 'Please add at least one variation to continue.';
            }
          })()}
        >
          <div className="space-y-2">
            {variationFields.fields.map((field, index) => {
              const errors = (() => {
                if (formState.errors.variations == null) { return; }
                if (formState.errors.variations.at == null) { return; }

                return formState.errors.variations.at(index);
              })();

              if (field.delete) {
                return (
                  <div key={field.key} data-testid="variation-undo-cell" className="shadow rounded-md flex items-center justify-between p-4">
                    <div>
                      <p className="text-sm sm:text-base font-medium">{field.name}</p>
                      <p className="mt-1 flex items-center text-xs sm:text-sm text-red-600">
                        Deleted
                      </p>
                    </div>

                    <div>
                      <Button
                        size="sm"
                        color="white"
                        rightIcon={faArrowRotateLeft}
                        onClick={() => variationFields.update(index, {
                          ...field,
                          delete: false,
                        })}
                        data-testid="variation-undo-cell-button"
                      >
                        Undo
                      </Button>
                    </div>
                  </div>
                );
              }

              return (
                <div key={field.key} data-testid="variation-form" className="border border-gray-300 rounded-md shadow-sm p-4">
                  {field.id && <input type="hidden" {...register(`variations.${index}.id`)} />}

                  <div className="space-y-4">
                    <InputField
                      label="Name"
                      placeholder="Variation"
                      {...register(`variations.${index}.name`)}
                      type="text"
                      required
                      error={errors?.name?.message}
                      data-testid="variation-name-input"
                    />

                    <FieldContainer label="Pricing" error={errors?.price?.message}>
                      <div className="flex gap-x-2">
                        <div className="flex-1">
                          <SelectField
                            {...register(`variations.${index}.price.type`)}
                            data-testid="variation-price-type-input"
                          >
                            <option value="FIXED">Fixed</option>
                            <option value="VARIABLE">Variable</option>
                          </SelectField>
                        </div>

                        {watch(`variations.${index}.price.type`) === 'FIXED' && (
                          <div className="flex-1">
                            <InputField
                              icon={faMoneyBillsSimple}
                              placeholder="19.99"
                              type="number"
                              step="0.01"
                              {...register(`variations.${index}.price.amount`)}
                              required
                              data-testid="variation-price-amount-input"
                            />
                          </div>
                        )}
                      </div>
                    </FieldContainer>

                    <InputField
                      icon={faCircleInfo}
                      label="Price display"
                      placeholder={(() => {
                        if (watch(`variations.${index}.price.type`) === 'FIXED') {
                          return `${currencyFromCode(user.currency, 19.99).format()} per set`;
                        }

                        return 'Varies';
                      })()}
                      type="text"
                      {...register(`variations.${index}.price.displayAs`)}
                      error={errors?.price?.displayAs?.message}
                      help={(
                        <span>
                          {watch(`variations.${index}.price.type`) === 'FIXED' && (
                            <React.Fragment>
                              <span>Optional.</span>&nbsp;
                            </React.Fragment>
                          )}

                          Describe how pricing works to your customers.
                        </span>
                      )}
                    />

                    <FieldContainer label="Duration" error={errors?.duration?.message}>
                      <div className="flex gap-x-2">
                        <div className="flex-1">
                          <SelectField
                            {...register(`variations.${index}.duration.hours`)}
                            data-testid="variation-duration-hours-input"
                          >
                            {_.range(24).map((h) => (
                              <option key={h} value={h}>{h} {pluralize('hours', h)}</option>
                            ))}
                          </SelectField>
                        </div>

                        <div className="flex-1">
                          <SelectField
                            {...register(`variations.${index}.duration.minutes`)}
                            data-testid="variation-duration-minutes-input"
                          >
                            {_.range(0, 60, 5).map((m) => (
                              <option key={m} value={m}>{m} {pluralize('minutes', m)}</option>
                            ))}
                          </SelectField>
                        </div>
                      </div>
                    </FieldContainer>

                    <div className="flex justify-between">
                      <div>
                        <Button
                          size="sm"
                          color="red"
                          onClick={() => {
                            if (field.id) {
                              return variationFields.update(index, {
                                ...field,
                                delete: true,
                              });
                            }

                            variationFields.remove(index);
                          }}
                          data-testid="variation-delete-button"
                        >
                          Delete
                        </Button>
                      </div>

                      <div className="flex space-x-2">
                        <Button
                          title="Move down"
                          size="sm"
                          color="white"
                          onClick={() => variationFields.move(index, index + 1)}
                          disabled={index === variationFields.fields.length - 1}
                          className={classNames({ 'cursor-not-allowed bg-gray-50': index === variationFields.fields.length - 1 })}
                          data-testid="variation-move-down-button"
                        >
                          <span className="sr-only">Move down</span>
                          <Icon icon={faArrowDown} className="w-3.5 h-3.5" />
                        </Button>

                        <Button
                          title="Move up"
                          size="sm"
                          color="white"
                          onClick={() => variationFields.move(index, index - 1)}
                          disabled={index === 0}
                          className={classNames({ 'cursor-not-allowed bg-gray-50': index === 0 })}
                          data-testid="variation-move-up-button"
                        >
                          <span className="sr-only">Move up</span>
                          <Icon icon={faArrowUp} className="w-3.5 h-3.5" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <Button fullWidth className="mt-2" onClick={onClickAddVariation}>
            Add variation
          </Button>
        </FieldContainer>
      </div>
    </SlideOverForm>
  );
}
