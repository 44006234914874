import React from 'react';

import { Switch as BaseSwitch } from '@headlessui/react';
import FieldContainer, { Props as FieldContainerProps } from './FieldContainer';

import classNames from 'classnames';

interface Props extends Pick<FieldContainerProps, 'error' | 'help'> {
  id?: string;

  checked?: boolean;
  onToggle?: (checked: boolean) => void;

  children?: React.ReactNode;
  'data-testid'?: string;
}

function Switch({ id, error, help, checked, onToggle, children, 'data-testid': dataTestId }: Props) {
  return (
    <FieldContainer id={id} error={error} help={help}>
      <div className="flex items-center">
        <BaseSwitch
          data-testid={dataTestId}
          checked={checked}
          onChange={onToggle}
          className={classNames(
            checked ? 'bg-black' : 'bg-gray-200',
            'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black',
          )}
          {...error && { 'aria-invalid': true, 'aria-errormessage': id ? `${id}-error` : undefined }}
          {...help && { 'aria-describedby': id ? `${id}-description` : undefined }}
        >
          <span
            aria-hidden="true"
            className={classNames(
              checked ? 'translate-x-5' : 'translate-x-0',
              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            )}
          />
        </BaseSwitch>

        {children && (
          <div className="ml-3 text-sm text-gray-900">
            {children}
          </div>
        )}
      </div>
    </FieldContainer>
  );
}

export default Switch;
