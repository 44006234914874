import { gql } from '@apollo/client';

export const USER_CREATE_UPDATE_PAYMENT_METHOD_URL = gql`
  mutation UserCreateUpdatePaymentMethodURL($input: UserCreateUpdatePaymentMethodURLInput!) {
    userCreateUpdatePaymentMethodUrl(input: $input) {
      url
    }
  }
`;

export interface Variables {
  input: {
    cancelPath: string;
    successPath: string;
  };
}

export interface Data {
  userCreateUpdatePaymentMethodUrl: {
    url: string;
  };
}
