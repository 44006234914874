import React, { useEffect } from 'react';

import { ThemeFile } from '../../../../lib/graphql/entities/theme/file';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import InputField from '../../../../components/fields/InputField';

import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../../../../components/Modal';
import Button from '../../../../components/Button';

interface Props extends ModalProps {
  file?: ThemeFile;
  onSubmit: (key: string) => void;
  submitting: boolean;
}

interface FormValues {
  filename: string;
}

export default function RenameFileModal({ file, onSubmit, submitting, ...props }: Props) {
  const extension = '.' + file?.key.split('.').pop();
  const filename = file?.key.split('/').pop()?.replace(extension, '');

  const { reset, watch, handleSubmit, register, formState } = useForm<FormValues>({
    resolver: zodResolver(z.object({
      filename: z.string().min(1, 'Please enter a valid file name.'),
    })),
  });

  useEffect(() => {
    reset({ filename });
  }, [filename, reset]);

  return (
    <Modal {...props} className="max-w-xl">
      <form
        onSubmit={handleSubmit(({ filename: newFilename }) => {
          if (file == null) { return; }

          onSubmit(file.key.replace(filename! + extension, newFilename + extension));
        })}
      >
        <div>
          <Dialog.Title className="text-base font-medium leading-6 text-gray-900" data-testid="rename-file-modal-title">
            Rename <code>{file?.key ?? 'file'}</code>
          </Dialog.Title>

          <Dialog.Description className="text-sm text-gray-500 mt-2">
            Renaming this file may break any other files that reference it.
            Make sure you know what you're doing.
          </Dialog.Description>
        </div>

        <div className="mt-4">
          <InputField
            label="New file name"
            placeholder="example"
            {...register('filename')}
            type="text"
            required
            error={formState.errors.filename?.message}
            className="pr-16"
            rightAddon={<span className="text-gray-500 text-xs font-mono">{extension}</span>}
          />
        </div>

        <div className="mt-4 flex gap-x-2">
          <Button
            className="flex-1"
            color="white"
            onClick={props.onClose}
          >
            Cancel
          </Button>

          <Button
            color="red"
            className="flex-1"
            type="submit"
            loading={submitting}
            disabled={submitting || watch('filename') === filename}
            data-testid="rename-file-modal-submit"
          >
            Rename
          </Button>
        </div>
      </form>
    </Modal>
  );
}
