// If value is an empty string, returns null.
export const cleanEmpty = (value: any) => {
  if (value === '') { return null; }

  return value;
};

// If value is an empty string, returns undefined.
export const ignoreEmpty = (value: any) => {
  if (value === '') { return undefined; }

  return value;
};
