import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import Navbar from './components/Navbar';
import SideMenu from './components/SideMenu';
import Loading from '../../components/Loading';
import Button from '../../components/Button';

import classNames from 'classnames';

interface Props {
  searchEnabled?: boolean;
  searchPlaceholder?: string;
  onSearch?: (query: string) => void;
  backPath?: string;
  title?: React.ReactNode;
  metaTitle?: string;
  text?: React.ReactNode;
  buttons?: React.ReactNode;
  loading?: boolean;
  paddingless?: boolean;
  children?: React.ReactNode;
}

export default function MainLayout({
  searchEnabled,
  searchPlaceholder,
  onSearch,
  backPath,
  title,
  metaTitle,
  text,
  buttons,
  loading,
  paddingless,
  children,
}: Props) {
  const navigate = useNavigate();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  return (
    <React.Fragment>
      <Helmet>
        {(metaTitle || title) && <title>{metaTitle || title}</title>}
      </Helmet>

      <div className="h-full">
        <SideMenu open={sideMenuOpen} setOpen={setSideMenuOpen} />

        <div className="md:pl-72 flex flex-col h-full">
          <Navbar
            setOpen={setSideMenuOpen}
            searchEnabled={searchEnabled}
            searchPlaceholder={searchPlaceholder}
            onSearch={onSearch}
          />

          <main className="flex-1 h-full overflow-y-scroll">
            <div className={classNames('mx-auto flex flex-col h-full gap-y-3', {
              'max-w-7xl px-4 sm:px-6 md:px-8 pt-6': !paddingless,
            })}>
              {backPath && (
                <div>
                  <Button
                    leftIcon={faArrowLeft}
                    size="sm"
                    color="gray"
                    rounded
                    onClick={() => navigate(backPath)}
                  >
                    Back
                  </Button>
                </div>
              )}

              {title && (
                <div className="lg:flex items-center justify-between gap-x-4">
                  <div>
                    <h1 className="text-3xl font-black text-gray-900">{title}</h1>
                    {text && <p className="text-[15px] text-gray-700">{text}</p>}
                  </div>

                  {buttons && <div className="flex gap-x-2 mt-3 lg:mt-0">{buttons}</div>}
                </div>
              )}

              {loading && <Loading className="flex-1 pb-32" />}
              {!loading && <div className="flex-1 pb-24">{children}</div>}
            </div>
          </main>
        </div>
      </div>
    </React.Fragment>
  );
}
