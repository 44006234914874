import React, { useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

export interface Props {
  open: boolean;
  onClose: () => void;
  className?: string;
  'data-testid'?: string;
}

export default function Modal({ open, onClose, children, className, 'data-testid': dataTestId }: React.PropsWithChildren<Props>) {
  const focusTrap = useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      {((dataTestId != null) && open) && <div data-testid={`${dataTestId}-open`} className="hidden" />}

      <Transition appear show={open} as={React.Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose} initialFocus={focusTrap}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={classNames('w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all', className)}>
                  <div ref={focusTrap} />

                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
}
