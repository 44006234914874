import React from 'react';

import FieldContainer, { Props as FieldContainerProps } from './FieldContainer';
import classNames from 'classnames';

type Props = FieldContainerProps & React.ComponentPropsWithoutRef<'textarea'>;

const TextAreaField = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { label, error, help, ...inputProps } = props;
  const id = inputProps.id || inputProps.name;

  return (
    <FieldContainer id={id} label={label} error={error} help={help}>
      <div className="relative rounded-md">
        <textarea
          ref={ref}
          id={id}
          {...error && { 'aria-invalid': true, 'aria-errormessage': id ? `${id}-error` : undefined }}
          {...help && { 'aria-describedby': id ? `${id}-description` : undefined }}
          {...inputProps}
          className={classNames('appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-sm', {
            'border-red-300 text-red-900 placeholder:text-red-300 focus:ring-red-500 focus:border-red-500': error != null,
          }, inputProps.className)}
        />
      </div>
    </FieldContainer>
  );
});

export default TextAreaField;
