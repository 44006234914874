import React from 'react';

import { faBookSection } from '@fortawesome/pro-regular-svg-icons';
import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../../../../components/Modal';
import Icon from '../../../../components/Icon';
import Button from '../../../../components/Button';

interface Props extends ModalProps {
  onConfirm: () => void;
  processing: boolean;
}

export default function OAuthTermsAgreementModal({ onConfirm, processing, ...props }: Props) {
  return (
    <Modal className="py-8" {...props} data-testid="oauth-terms-agreement-modal">
      <div className="flex justify-center">
        <Icon
          icon={faBookSection}
          className="h-8 w-8"
        />
      </div>

      <Dialog.Title className="text-center text-lg font-medium leading-6 text-gray-900 mt-4">
        One more thing!
      </Dialog.Title>

      <Dialog.Description className="text-center text-xs text-gray-500 mt-2">
        To sign up, you'll need to agree to the <a href="https://myprices.io/policies/terms" target="_blank" rel="noopener noreferrer" className="font-medium text-black hover:text-neutral-900">Terms of Service</a>,
        and agree to the processing of your data as described in the <a href="https://myprices.io/policies/privacy" target="_blank" rel="noopener noreferrer" className="font-medium text-black hover:text-neutral-900">Privacy Policy</a>.
      </Dialog.Description>

      <div className="mt-4 flex gap-x-2">
        <Button
          className="flex-1"
          color="white"
          onClick={props.onClose}
        >
          Cancel
        </Button>

        <Button
          className="flex-1"
          onClick={onConfirm}
          loading={processing}
          disabled={processing}
        >
          I agree
        </Button>
      </div>
    </Modal>
  );
}
