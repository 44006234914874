import { gql } from '@apollo/client';

export const SIGN_OUT = gql`
  mutation SignOut {
    authSignOut {
      success
    }
  }
`;

export interface Data {
  authSignOut: {
    success: boolean;
  };
}
