import React, { useEffect } from 'react';

import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../../lib/graphql/entities/query';
import { PRICE_FIELDS } from '../../../lib/graphql/fragments/price';

import { useNavigate } from 'react-router-dom';
import paths from '../../../paths';

import { Helmet } from 'react-helmet';
import Loading from '../../../components/Loading';
import DotPattern from '../../../components/layout/DotPattern';
import Logo from '../../../components/layout/Logo';
import PillTabs from '../../../components/PillTabs';
import Plans from './Plans';
import Footer from '../components/Footer';

export const GET_PRICES = gql`
  ${PRICE_FIELDS}

  query GetPrices($currency: Currency!) {
    prices(currency: $currency) {
      ...PriceFields
    }
  }
`;

export default function SelectPlanPage() {
  const navigate = useNavigate();
  const [getPrices, getPricesQuery] = useLazyQuery<Query>(GET_PRICES);
  const { data } = useQuery<Query>(GET_INITIAL_DATA, { pollInterval: 1000 });

  useEffect(() => {
    if (data == null) { return; }

    // Redirect if already has a subscription.
    if (data.viewer!.billing != null) {
      return navigate(paths.onboarding.stripeCallback);
    }

    // Use user's currency to get prices.
    getPrices({
      variables: { currency: data.viewer!.stripeCurrency },
    });
  }, [data, navigate, getPrices]);

  if (getPricesQuery.data == null) {
    return <Loading className="h-full" />;
  }

  const prices = getPricesQuery.data!.prices!;
  return (
    <React.Fragment>
      <Helmet>
        <title>Select a plan</title>
      </Helmet>

      <div className="flex flex-col min-h-full pt-16 lg:pt-24 px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="flex-1 relative max-w-2xl mx-auto">
          <DotPattern className="absolute left-full transform translate-x-1/2" />
          <DotPattern className="absolute right-full bottom-0 transform -translate-x-1/2" />

          <Logo />

          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Start your 7-day trial</h2>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              No credit card required! We&apos;ll ask for that when your trial ends.
            </p>
          </div>

          <PillTabs
            className="mt-8"
            panelClassName="mt-4 sm:mt-8"
            tabs={[
              {
                key: 'monthly',
                title: 'Monthly',
                render: () => (
                  <Plans
                    prices={prices.filter((p) => p.interval === 'MONTH')}
                  />
                ),
              },
              {
                key: 'yearly',
                title: 'Yearly',
                render: () => (
                  <Plans
                    prices={prices.filter((p) => p.interval === 'YEAR')}
                  />
                ),
              },
            ]}
          />
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}
