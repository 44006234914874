import React from 'react';

import { Country } from '../../../lib/graphql/entities/country';

import SelectField, { Props as SelectFieldProps } from '../SelectField';

export const COUNTRIES_WITHOUT_REGION_INPUT: Country[] = ['GB'];

interface Region {
  code: string;
  name: string;
}

export const US_REGIONS: Region[] = [
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'DC', name: 'District of Columbia' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyoming' },
];

export const CA_REGIONS: Region[] = [
  { code: 'AB', name: 'Alberta' },
  { code: 'BC', name: 'British Columbia' },
  { code: 'MB', name: 'Manitoba' },
  { code: 'NB', name: 'New Brunswick' },
  { code: 'NL', name: 'Newfoundland and Labrador' },
  { code: 'NT', name: 'Northwest Territories' },
  { code: 'NS', name: 'Nova Scotia' },
  { code: 'NU', name: 'Nunavut' },
  { code: 'ON', name: 'Ontario' },
  { code: 'PE', name: 'Prince Edward Island' },
  { code: 'QC', name: 'Quebec' },
  { code: 'SK', name: 'Saskatchewan' },
  { code: 'YT', name: 'Yukon' },
];

export const GB_REGIONS: Region[] = [
  { code: 'ENG', name: 'England' },
  { code: 'NIR', name: 'Northern Ireland' },
  { code: 'SCT', name: 'Scotland' },
  { code: 'WLS', name: 'Wales' },
];

export const AU_REGIONS: Region[] = [
  { code: 'ACT', name: 'Australian Capital Territory' },
  { code: 'JBT', name: 'Jervis Bay Territory' },
  { code: 'NSW', name: 'New South Wales' },
  { code: 'NT', name: 'Northern Territory' },
  { code: 'QLD', name: 'Queensland' },
  { code: 'SA', name: 'South Australia' },
  { code: 'TAS', name: 'Tasmania' },
  { code: 'VIC', name: 'Victoria' },
  { code: 'WA', name: 'Western Australia' },
];

interface Props extends Omit<SelectFieldProps, 'children'> {
  country: Country;
}

const RegionField = React.forwardRef<HTMLSelectElement, Props>(({ country, ...props }, ref) => {
  const regions = (() => {
    switch (country) {
      case 'US':
        return US_REGIONS;
      case 'CA':
        return CA_REGIONS;
      case 'GB':
        return GB_REGIONS;
      case 'AU':
        return AU_REGIONS;
    }
  })();

  const label = (() => {
    switch (country) {
      case 'US':
        return 'State';
      case 'CA':
        return 'Province';
      case 'GB':
        return 'Region';
      case 'AU':
        return 'State/territory';
    }
  })();

  return (
    <SelectField
      ref={ref}
      label={label}
      defaultValue=""
      {...props}
    >
      <option value="" disabled hidden>{label}</option>

      {regions.map(({ code, name }) => (
        <option key={code} value={code}>{name}</option>
      ))}
    </SelectField>
  );
});

export default RegionField;
