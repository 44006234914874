import React from 'react';

import { useNavigate } from 'react-router-dom';
import paths from '../../../../../paths';
import type { LocationState } from '../../../../website/customize/CustomizePage';

import TaskCell from '../TaskCell';

interface Props {
  completed: boolean;
}

export default function AddOpenPeriods({ completed }: Props) {
  const navigate = useNavigate();

  return (
    <TaskCell
      title="Set your business hours"
      text="These business hours will be displayed on your website."
      completed={completed}
      onClick={() => navigate(paths.website.customize, {
        state: { section: 'open-periods' } as LocationState,
      })}
      data-testid="add-open-periods-task-cell"
    />
  );
}
