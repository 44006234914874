import React, { useState, useReducer, useRef } from 'react';
import { useUpdateEffect } from 'react-use';

import { gql, useQuery, useMutation } from '@apollo/client';
import {
  SERVICE_UPDATE,
  Data as UpdateData,
  Variables as UpdateVariables,
} from '../../lib/graphql/mutations/service/update';
import { Query } from '../../lib/graphql/entities/query';
import { Service } from '../../lib/graphql/entities/service';
import { USER_FIELDS } from '../../lib/graphql/fragments/user';
import { SERVICE_FIELDS } from '../../lib/graphql/fragments/service';
import { getCreateServiceURLForBookingProvider } from '../../lib/graphql/entities/booking-provider';
import UserDecorator from '../../lib/graphql/decorators/user';

import { toast } from 'react-hot-toast';
import MainLayout from '../../layouts/MainLayout';
import CreateServiceSlideOverForm from './components/CreateServiceSlideOverForm';
import UpdateServiceSlideOverForm from './components/UpdateServiceSlideOverForm';
import ConnectBookingProviderModal, { connectBookingProviderModalReducer } from '../../components/booking-provider/ConnectBookingProviderModal';
import SortableList from '../../components/SortableList';
import ServiceCell from './components/ServiceCell';
import EmptyState from '../../components/EmptyState';
import Button from '../../components/Button';

import { arrayMove } from '@dnd-kit/sortable';
import { produce } from 'immer';

export const GET_SERVICES = gql`
  ${USER_FIELDS}
  ${SERVICE_FIELDS}

  query GetServices {
    viewer {
      ...UserFields
      services {
        ...ServiceFields
      }
    }
  }
`;

export default function ServicesPage() {
  const [updateService] = useMutation<UpdateData, UpdateVariables>(SERVICE_UPDATE);

  const [query, setQuery] = useState<string>();
  const { client, data, loading } = useQuery<Query>(GET_SERVICES, {
    fetchPolicy: 'network-only',
  });

  const services = data?.viewer?.services || [];

  // Used to scroll to the last created service when the data changes.
  const lastCreatedService = useRef<Service>();
  useUpdateEffect(() => {
    if (lastCreatedService.current == null) { return; }

    if (services.find((service) => service.id === lastCreatedService.current!.id)) {
      document.querySelector(`.service-cell[data-id="${lastCreatedService.current.id}"]`)?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    // Reset the ref so it doesn't scroll again.
    lastCreatedService.current = undefined;
  }, [services]);

  type SlideOverState = (
    { open: true; service: Service | null; }
      | { open: false; service: Service | null; }
  );

  type SlideOverAction = (
    { type: 'OPEN'; service: Service | null; }
      | { type: 'CLOSE'; }
  );

  const slideOverReducer: React.Reducer<SlideOverState, SlideOverAction> = (state, action) => {
    switch (action.type) {
      case 'OPEN':
        return { open: true, service: action.service };
      case 'CLOSE':
        return { ...state, open: false };
    }
  };

  const [slideOverState, slideOverDispatch] = useReducer(slideOverReducer, { open: false, service: null });

  const [
    connectBookingProviderModalState,
    connectBookingProviderModalDispatch,
  ] = useReducer(connectBookingProviderModalReducer, { open: false, bookingProvider: undefined });

  const [reordering, setReordering] = useState(false);
  useUpdateEffect(() => {
    if (reordering) {
      toast.loading(
        <div className="flex items-center">
          <span className="text-sm">Reordering. Drag & drop prices to move them.</span>

          <Button
            color="white"
            size="custom"
            className="text-xs px-2 py-1 ml-4"
            onClick={() => setReordering(false)}
          >
            Stop
          </Button>
        </div>,
      );

      return;
    }

    toast.dismiss();
  }, [reordering]);

  const renderContent = () => {
    // Loading indicator already rendered by MainLayout
    if (loading) { return null; }

    const filteredServices = services.filter((service) => {
      if (query == null) { return true; }

      return service.name.toLowerCase().includes(query.toLowerCase());
    });

    if (filteredServices.length === 0) {
      if (query) {
        return (
          <EmptyState
            title="No results"
            text="We couldn't find any prices with that name."
          />
        );
      }

      if (data!.viewer!.bookingProvider) {
        const providerHumanized = new UserDecorator(data!.viewer!).bookingProvider;
        return (
          <EmptyState
            title="No prices set"
            text={`Get started by adding your prices to ${providerHumanized}.`}
            buttons={(
              <Button
                onClick={() => {
                  return window.open(getCreateServiceURLForBookingProvider(data!.viewer!.bookingProvider!), '_blank')!.focus();
                }}
              >
                {(data!.viewer!.bookingProvider === 'SQUARE') && (
                  <img
                    className="w-5 h-5 invert mr-1"
                    src={require('../../assets/images/icons/square.png')}
                    alt=""
                  />
                )}

                Open {providerHumanized}
              </Button>
            )}
          />
        );
      }

      return (
        <EmptyState
          title="No prices set"
          text="Get started by setting your first price or connecting to Square."
          buttons={(
            <React.Fragment>
              <Button color="white" onClick={() => slideOverDispatch({ type: 'OPEN', service: null })}>
                Set first price
              </Button>

              <Button
                onClick={() => connectBookingProviderModalDispatch({
                  type: 'OPEN',
                  bookingProvider: 'SQUARE',
                })}
              >
                <img
                  className="w-5 h-5 invert mr-1"
                  src={require('../../assets/images/icons/square.png')}
                  alt=""
                />

                Connect to Square
              </Button>
            </React.Fragment>
          )}
        />
      );
    }

    return (
      <div className="bg-white shadow rounded-md overflow-hidden divide-y divide-gray-200">
        <SortableList
          items={filteredServices.map((s) => s.id)}
          onDragEnd={({ active, over }) => {
            if (active.id !== over!.id) {
              const oldIndex = services.map((s) => s.id).indexOf(active.id as string);
              const newIndex = services.map((s) => s.id).indexOf(over!.id as string);
              client.cache.updateQuery<Query>({ query: GET_SERVICES }, (cachedData) => {
                return produce(cachedData!, (draft) => {
                  const services = arrayMove(draft.viewer!.services!, oldIndex, newIndex);
                  draft.viewer!.services = services.map((service, index) => ({
                    ...service,
                    position: index + 1,
                  }));
                });
              });

              updateService({
                fetchPolicy: 'no-cache',
                variables: {
                  input: {
                    id: active.id as string,
                    position: newIndex + 1,
                  },
                },
                update: () => {
                  toast.success('Moved service.');
                },
              });
            }
          }}
        >
          {filteredServices.map((service) => (
            <ServiceCell
              key={service.id}
              service={service}
              sortEnabled={reordering}
              onClick={() => {
                slideOverDispatch({ type: 'OPEN', service });
              }}
            />
          ))}
        </SortableList>
      </div>
    );
  };

  return (
    <MainLayout
      title="Prices"
      text="These will show up on your website for customers to book."
      loading={loading}
      searchEnabled={!reordering}
      searchPlaceholder="Search prices..."
      onSearch={setQuery}
      buttons={!loading && (
        <React.Fragment>
          {!reordering && (
            <Button
              size="sm"
              onClick={() => {
                if (data!.viewer!.bookingProvider != null) {
                  return window.open(getCreateServiceURLForBookingProvider(data!.viewer!.bookingProvider!), '_blank')!.focus();
                }

                slideOverDispatch({ type: 'OPEN', service: null });
              }}
            >
              New price
            </Button>
          )}

          {(!query && data!.viewer!.bookingProvider == null && services.length > 1) && (
            <Button size="sm" color="white" onClick={() => setReordering((value) => !value)} data-testid="reorder-button">
              {reordering ? 'Stop reordering' : 'Reorder'}
            </Button>
          )}
        </React.Fragment>
      )}
    >
      {renderContent()}

      <CreateServiceSlideOverForm
        user={data?.viewer!}
        open={slideOverState.service == null && slideOverState.open}
        onClose={() => slideOverDispatch({ type: 'CLOSE' })}
        onCreate={(service) => {
          lastCreatedService.current = service;
        }}
      />

      <UpdateServiceSlideOverForm
        user={data?.viewer!}
        service={slideOverState.service}
        open={slideOverState.service != null && slideOverState.open}
        onClose={() => slideOverDispatch({ type: 'CLOSE' })}
      />

      <ConnectBookingProviderModal
        user={data?.viewer!}
        open={connectBookingProviderModalState.open}
        bookingProvider={connectBookingProviderModalState.bookingProvider}
        onClose={() => connectBookingProviderModalDispatch({ type: 'CLOSE' })}
      />
    </MainLayout>
  );
}
