import React, { useState } from 'react';

import { useMaskito } from '@maskito/react';
import FieldContainer, { Props as FieldContainerProps } from './FieldContainer';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../Icon';

import classNames from 'classnames';
import colors from 'tailwindcss/colors';

export const COLORS = [
  '#8ed1fc', '#f78da7', '#eb144c',
  '#ff6900', '#fcb900', '#00d084',
  '#0693e3', '#9900ef', '#0a0a0a',
];

interface Props extends FieldContainerProps, Omit<React.ComponentPropsWithoutRef<'input'>, 'defaultValue' | 'onChange'> {
  defaultValue?: string;
  onChange?: (color: string) => void;
}

export default function ColorField({ label, error, help, defaultValue, onChange: onChangeCallback, ...inputProps }: Props) {
  const id = inputProps.id || inputProps.name;

  const [value, setValue] = useState(defaultValue?.toLowerCase() || '');
  const inputRef = useMaskito({
    options: {
      mask: /^#[0-9a-f]{0,6}$/i,
    },
  });

  const onChange = (value: string) => {
    setValue(value);
    onChangeCallback?.(value);
  };

  return (
    <FieldContainer id={id} label={label} error={error} help={help} data-testid="color-field">
      <div
        className="grid grid-cols-6 gap-2 p-2 w-fit max-w-full shadow-sm border border-2 rounded-lg"
        style={{
          borderColor: (() => {
            if (error != null) {
              return colors.red[500];
            }

            return value || 'transparent';
          })(),
        }}
        data-testid="color-field-container"
      >
        {COLORS.map((color) => (
          <div
            key={color}
            className="h-8 w-8 rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transition-transform"
            style={{ backgroundColor: color }}
            onClick={() => onChange(color)}
            data-testid="color-option"
          >
            {(value === color) && (
              <Icon
                icon={faCheck}
                className="h-4 w-4 text-white"
                data-testid="color-option-checkmark"
              />
            )}
          </div>
        ))}

        <div className="col-span-3">
          <input
            ref={inputRef}
            id={id}
            data-testid="color-field-input"
            {...error && { 'aria-invalid': true, 'aria-errormessage': id ? `${id}-error` : undefined }}
            {...help && { 'aria-describedby': id ? `${id}-description` : undefined }}
            {...inputProps}
            type="text"
            className={classNames('appearance-none block w-28 h-full border border-gray-300 rounded-md shadow-sm px-3 py-0 text-sm text-center uppercase placeholder:text-gray-400 focus:outline-none focus:ring-black focus:border-black disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400', {
              'border-red-300 text-red-900 placeholder:text-red-300 focus:ring-red-500 focus:border-red-500': error != null,
            })}
            value={value}
            onInput={(e) => onChange(e.currentTarget.value.toLowerCase())}
          />
        </div>
      </div>
    </FieldContainer>
  );
}
