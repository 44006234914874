import React from 'react';

export interface Props {
  id?: string;
  label?: string;
  error?: React.ReactNode;
  help?: React.ReactNode;
}

export default function FieldContainer({ id, label, error, help, children, ...props }: React.PropsWithChildren<Props> & React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div {...props}>
      {label && <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">{label}</label>}

      {children}

      {error && (
        <p className="mt-2 text-sm text-red-600" id={id ? `${id}-error` : undefined}>
          {error}
        </p>
      )}

      {(help && error == null) && (
        <p className="mt-2 text-sm text-gray-500" id={id ? `${id}-description` : undefined}>
          {help}
        </p>
      )}
    </div>
  );
}
