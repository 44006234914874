import React from 'react';

import { motion } from 'framer-motion';
import Plan from './Plan';
import { Price } from '../../../lib/graphql/entities/price';

interface Props {
  prices: Price[];
}

export default function Plans({ prices }: Props) {
  return (
    <motion.ul
      className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0"
      variants={{
        visible: {
          transition: {
            delayChildren: 0.15,
            staggerChildren: 0.1,
          },
        },
      }}
      initial="hidden"
      animate="visible"
    >
      {prices.map((price) => (
        <motion.li
          key={price.id}
          variants={{
            hidden: { y: 20, opacity: 0 },
            visible: { y: 0, opacity: 1 },
          }}
        >
          <Plan price={price} />
        </motion.li>
      ))}
    </motion.ul>
  );
}
