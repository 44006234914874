import React from 'react';
import { useAsync } from 'react-use';

import { gql, useQuery } from '@apollo/client';
import { getBearerToken } from './lib/graphql/client';
import { USER_FIELDS } from './lib/graphql/fragments/user';
import { Query } from './lib/graphql/entities/query';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import paths from './paths';

import Loading from './components/Loading';

import { Crisp } from 'crisp-sdk-web';
import { identify as identifyCrisp } from './lib/crisp';

export const GET_INITIAL_DATA = gql`
  ${USER_FIELDS}

  query GetInitialData {
    viewer {
      ...UserFields
    }
  }
`;

export default function Root() {
  const location = useLocation();
  const navigate = useNavigate();

  const { data, loading, client } = useQuery<Query>(GET_INITIAL_DATA);

  useAsync(async () => {
    if (data == null) { return; }

    const { viewer } = data;
    if (viewer == null) {
      // Reset if authentication token is no longer valid.
      if (getBearerToken() != null) {
        return await client.resetStore();
      }

      return;
    }

    // Identify with Crisp
    Crisp.session.onLoaded(() => {
      identifyCrisp(viewer);
    });
  }, [data]);

  // Redirect to appropriate initial page
  useAsync(async () => {
    if (loading) { return; }

    // Only redirect if visiting `/` route.
    if (location.pathname === paths.root) {
      if (data!.viewer != null) {
        return navigate(paths.dashboard);
      } else {
        return navigate(paths.auth.signIn);
      }
    }
  }, [loading, data, location, navigate]);

  if (loading) {
    return <Loading className="h-full" />;
  }

  return <Outlet />;
}
