import client from '../../graphql/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../graphql/entities/query';

import { redirect } from 'react-router-dom';
import paths from '../../../paths';

import { toast } from 'react-hot-toast';
import { TOASTER_LOAD_TIME } from './utils';

// Requires that user be signed in.
export const signedInOnlyLoader = async (): Promise<Response | null> => {
  const { data } = await client.query<Query>({ query: GET_INITIAL_DATA });

  if (data.viewer == null) {
    setTimeout(() => toast.error('You must log in first.'), TOASTER_LOAD_TIME);
    return redirect(paths.auth.signIn);
  }

  return null;
};

export default signedInOnlyLoader;
