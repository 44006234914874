import { gql } from '@apollo/client';
import { SERVICE_FIELDS } from '../../fragments/service';
import { Service } from '../../entities/service';

export const SERVICE_UPDATE = gql`
  ${SERVICE_FIELDS}

  mutation ServiceUpdate($input: ServiceUpdateInput!) {
    serviceUpdate(input: $input) {
      service {
        ...ServiceFields
      }
    }
  }
`;

export interface Variables {
  input: {
    id: string;
    name?: string;
    description?: string;
    variations?: Array<{
      id?: string;
      name?: string;
      duration?: number;
      price?: {
        type?: 'FIXED' | 'VARIABLE';
        amount?: number | null;
        displayAs?: string | null;
      };

      position?: number;
      delete?: boolean;
    }>;
    position?: number;
  };
}

export interface Data {
  serviceUpdate: {
    service: Service;
  };
}
