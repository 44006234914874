import currency, { Options } from 'currency.js';
import { Currency as CurrencyCode } from '../graphql/entities/currency';

export const flagForCurrency = (code: CurrencyCode): string => {
  switch(code) {
    case 'USD':
      return '🇺🇸';
    case 'CAD':
      return '🇨🇦';
    case 'GBP':
      return '🇬🇧';
    case 'AUD':
      return '🇦🇺';
  }
};

export const currencyFromCode = (code: CurrencyCode, value: number, options?: Options) => {
  switch (code) {
  case 'USD':
  case 'CAD':
  case 'AUD':
    return currency(value, options);
  case 'GBP':
    return currency(value, { symbol: '£', ...options });
  }
};

export const removeTrailingZeros: currency.Format = (instance, options) => {
  // Create a string representation to simply remove the '.00' substring
  const formatted = (() => {
    // Prevent infinite loop
    delete options!.format;

    // `instance!.value` already returns the dollar amount
    delete options!.fromCents;

    return currency(instance!.value, options).format();
  })();

  if (instance!.cents() === 0) {
    // Convert '$1.00' to '$1'
    return formatted.replace(/\.00/g, '');
  }

  return formatted;
};
