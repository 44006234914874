import { gql } from '@apollo/client';

export const PEXELS_VIDEO_FIELDS = gql`
  fragment PexelsVideoFields on PexelsVideo {
    id
    user {
      id
      name
      url
    }
    thumbnail
    source
  }
`;
