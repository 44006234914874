export const MYPRICES_GRAPHQL_ENDPOINT = (() => {
  switch (process.env.REACT_APP_ENVIRONMENT as ReactAppEnvironment) {
    case 'production':
    case 'test':
      return 'https://api.myprices.io/graphql';
    case 'staging':
    case 'development':
      return 'https://api.staging.myprices.io/graphql';
  }
})();

export const MYPRICES_SITE_ADDRESSES = (() => {
  switch (process.env.REACT_APP_ENVIRONMENT as ReactAppEnvironment) {
    case 'production':
    case 'test':
      return ['3.33.128.57', '15.197.128.203'];
    case 'staging':
    case 'development':
      return ['3.33.131.8', '15.197.132.200'];
  }
})();

export const MYPRICES_SITE_DOMAIN = (() => {
  switch (process.env.REACT_APP_ENVIRONMENT as ReactAppEnvironment) {
    case 'production':
    case 'test':
      return 'myprices.site';
    case 'staging':
    case 'development':
      return 'staging.myprices.site';
  }
})();

export const GOOGLE_OAUTH_CLIENT_ID = '399372085741-5dtnna7tbhqkinpmqpoqrmav9ne1i7m2.apps.googleusercontent.com';

export const MAPBOX_TOKEN = 'pk.eyJ1IjoibXlwcmljZXMiLCJhIjoiY2xta212MWlhMDN1aDJpcWFjYmk5MnIzMCJ9.kfqL_en_c-OZpjpFgp0vJA';

export const CRISP_WEBSITE_ID = '2a52f2ca-5094-4879-b919-17a197f6c527';

export const SENTRY_DSN = 'https://d0bf37f81dc772553e31ad81739c9b36@o4507667953745920.ingest.us.sentry.io/4507671126278144';
