import React from 'react';

import SelectField, { Props as SelectFieldProps } from '../SelectField';

type Props = Omit<SelectFieldProps, 'children'>;

const CountryField = React.forwardRef<HTMLSelectElement, Props>((props, ref) => {
  return (
    <SelectField
      ref={ref}
      label="Country"
      defaultValue=""
      {...props}
    >
      <option value="" disabled hidden>Country</option>

      <option value="US">United States 🇺🇸</option>
      <option value="CA">Canada 🇨🇦</option>
      <option value="GB">United Kingdom 🇬🇧</option>
      <option value="AU">Australia 🇦🇺</option>
    </SelectField>
  );
});

export default CountryField;
