import { gql } from '@apollo/client';

export const SERVICE_DELETE = gql`
  mutation ServiceDelete($input: ServiceDeleteInput!) {
    serviceDelete(input: $input) {
      id
    }
  }
`;

export interface Variables {
  input: {
    id: string;
  };
}

export interface Data {
  serviceDelete: {
    id: string;
  };
}
