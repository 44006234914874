import { gql } from '@apollo/client';

export const UNSPLASH_PHOTO_FIELDS = gql`
  fragment UnsplashPhotoFields on UnsplashPhoto {
    id
    user {
      id
      name
      url
    }
    source
  }
`;
