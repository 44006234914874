import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { SIGN_OUT } from '../../lib/graphql/mutations/auth/sign-out';

import { useLocation, useNavigate } from 'react-router-dom';
import paths from '../../paths';

import Loading from '../../components/Loading';

export interface LocationState {
  validInitiation: boolean;
}

export default function SignOutPage() {
  const locationState: LocationState | undefined = useLocation().state;
  const navigate = useNavigate();

  const [signOut, { client }] = useMutation(SIGN_OUT, {
    update: async () => {
      await client.resetStore();
      navigate(paths.root);
    },
  });

  useEffect(() => {
    if (!locationState?.validInitiation) {
      return navigate(paths.root);
    }

    signOut();
  }, [locationState, navigate, signOut]);

  return (
    <Loading
      text="Signing out..."
      className="h-full"
    />
  );
}
