import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { CONFIRM, Data as ConfirmData, Variables as ConfirmVariables } from '../../lib/graphql/mutations/auth/confirm';

import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from '../../paths';

import { toast } from 'react-hot-toast';
import DotPattern from '../../components/layout/DotPattern';
import Logo from '../../components/layout/Logo';
import Button from '../../components/Button';
import Loading from '../../components/Loading';

export default function ConfirmPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [success, setSuccess] = useState(false);
  const [confirm, { loading, error }] = useMutation<ConfirmData, ConfirmVariables>(CONFIRM);
  useEffect(() => {
    if (token == null) { return; }

    confirm({
      variables: { input: { token } },
      update: () => setSuccess(true),
    }).catch(({ graphQLErrors }) => {
      // Don't dispatch toasts for these error classes
      // as we already render custom content for them below.
      if (graphQLErrors[0]?.extensions?.code === 'NOT_FOUND') {
        toast.remove();
      }
    });
  }, [token, confirm]);

  if (loading) { return <Loading className="h-full" />; }

  const renderContent = () => {
    if (token == null || error?.graphQLErrors[0]?.extensions?.code === 'NOT_FOUND') {
      return (
        <div data-testid="not-found-error-container">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Hmm... 🤔</h2>
            <p className="mt-4 text-base leading-6 text-gray-500">
              Seems like this link isn&apos;t valid. Can you try sending yourself another confirmation link?
            </p>
          </div>

          <div className="mt-8">
            <Button size="xl" fullWidth onClick={() => navigate(paths.auth.sendConfirmation)}>Resend confirmation link</Button>
          </div>
        </div>
      );
    }

    if (success) {
      return (
        <div data-testid="success-container">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Account confirmed!</h2>
            <p className="mt-4 text-base leading-6 text-gray-500">
              Your email address has been confirmed. You may log in now.
            </p>
          </div>

          <div className="mt-8">
            <Button size="xl" fullWidth onClick={() => navigate(paths.root)}>
              Continue
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="min-h-full py-16 lg:py-24 px-4 sm:px-6 lg:px-8 overflow-hidden">
      <div className="relative max-w-xl mx-auto">
        <DotPattern className="absolute left-full transform translate-x-1/2" />
        <DotPattern className="absolute right-full bottom-0 transform -translate-x-1/2" />

        <Logo />

        {renderContent()}
      </div>
    </div>
  );
}
