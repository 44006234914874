import React from 'react';

import { useMutation } from '@apollo/client';
import {
  RESET_PASSWORD,
  Data as ResetPasswordData,
  Variables as ResetPasswordVariables,
} from '../../lib/graphql/mutations/auth/reset-password';

import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from '../../paths';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { Helmet } from 'react-helmet';
import { toast } from 'react-hot-toast';
import DotPattern from '../../components/layout/DotPattern';
import Logo from '../../components/layout/Logo';
import InputField from '../../components/fields/InputField';
import Button from '../../components/Button';

interface FormValues {
  password: string;
}

export default function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const [resetPassword, { error }] = useMutation<ResetPasswordData, ResetPasswordVariables>(RESET_PASSWORD);
  const { handleSubmit, register, formState } = useForm<FormValues>({
    resolver: zodResolver(z.object({
      password: z.string().min(1, { message: 'Please enter your new password.' }),
    })),
  });

  const onSubmit = async ({ password }: FormValues) => {
    await resetPassword({
      variables: { input: { token: token!, password } },
      onError: ({ graphQLErrors }) => {
        // Don't dispatch toasts for these error classes
        // as we already render custom content for them below.
        if (['NOT_FOUND', 'TOKEN_EXPIRED'].includes(graphQLErrors[0]?.extensions?.code as string)) {
          toast.remove();
        }
      },
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Reset your password</title>
      </Helmet>

      <div className="min-h-full py-16 lg:py-24 px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="relative max-w-xl mx-auto">
          <DotPattern className="absolute left-full transform translate-x-1/2" />
          <DotPattern className="absolute right-full bottom-0 transform -translate-x-1/2" />

          <Logo />

          {(() => {
            if (token == null || error?.graphQLErrors[0]?.extensions?.code === 'NOT_FOUND') {
              return (
                <div data-testid="not-found-error-container">
                  <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Hmm... 🤔</h2>
                    <p className="mt-4 text-base leading-6 text-gray-500">
                      Seems like this link isn&apos;t valid. Can you try sending yourself another reset password link?
                    </p>
                  </div>

                  <div className="mt-8">
                    <Button size="xl" fullWidth onClick={() => navigate(paths.auth.forgotPassword)}>Resend reset password link</Button>
                  </div>
                </div>
              );
            }

            if (error?.graphQLErrors[0]?.extensions?.code === 'TOKEN_EXPIRED') {
              return (
                <div data-testid="token-expired-error-container">
                  <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Hmm... 🤔</h2>
                    <p className="mt-4 text-base leading-6 text-gray-500">
                      Seems like this link has already expired. Can you try sending yourself another reset password link?
                    </p>
                  </div>

                  <div className="mt-8">
                    <Button size="xl" fullWidth onClick={() => navigate(paths.auth.forgotPassword)}>Resend reset password link</Button>
                  </div>
                </div>
              );
            }

            if (formState.isSubmitSuccessful) {
              return (
                <div data-testid="success-container">
                  <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Password reset!</h2>
                    <p className="mt-4 text-lg leading-6 text-gray-500">
                      You may now log in with your new password.
                    </p>
                  </div>

                  <div className="mt-8">
                    <Button size="xl" fullWidth onClick={() => navigate(paths.auth.signIn)}>Continue to login</Button>
                  </div>
                </div>
              );
            }

            return (
              <div data-testid="form-container">
                <div className="text-center">
                  <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Reset your password</h2>
                  <p className="mt-4 text-lg leading-6 text-gray-500">
                    Make sure it&apos;s unique &amp; secure.
                  </p>
                </div>
                <div className="mt-8">
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <InputField
                      label="New password"
                      placeholder="Create a new password"
                      {...register('password')}
                      type="password"
                      autoComplete="new-password"
                      required
                      error={formState.errors.password?.message}
                    />

                    <Button type="submit" size="xl" fullWidth loading={formState.isSubmitting} disabled={formState.isSubmitting}>
                      Reset password
                    </Button>
                  </form>
                </div>
              </div>
            );
          })()}
        </div>
      </div>
    </React.Fragment>
  );
}
