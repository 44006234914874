import React from 'react';

import { useNavigate } from 'react-router-dom';
import paths from '../../paths';
import type { LocationState } from '../../pages/auth/DeleteAccountPage';

import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../Modal';
import Button from '../Button';

export default function DeleteAccountModal(props: ModalProps) {
  const navigate = useNavigate();

  return (
    <Modal {...props} className="py-8" data-testid="delete-account-modal">
      <Dialog.Title className="text-center text-lg font-medium leading-6 text-gray-900 mt-2">
        Delete your account?
      </Dialog.Title>

      <Dialog.Description className="text-center text-sm text-gray-500 mt-2">
        You will no longer be charged &amp; your data will be permanently deleted.
      </Dialog.Description>

      <div className="mt-4 flex gap-x-2">
        <Button
          className="flex-1"
          color="white"
          onClick={props.onClose}
        >
          Close
        </Button>

        <Button
          className="flex-1"
          color="red"
          onClick={() => navigate(paths.auth.deleteAccount, {
            state: { validInitiation: true } as LocationState,
          })}
        >
          Yes, delete my account.
        </Button>
      </div>
    </Modal>
  );
}
