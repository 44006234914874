import { gql } from '@apollo/client';

export const DOMAIN_FIELDS = gql`
  fragment DomainFields on Domain {
    id
    name
    code
    dnsStatus {
      connected
      rootA1
      rootA2
      wwwA1
      wwwA2
      verificationTxt
    }
  }
`;
