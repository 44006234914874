import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { BOOKING_PROVIDER_CONNECT, Data, Variables } from '../../../lib/graphql/mutations/booking-provider/connect';
import UserDecorator from '../../../lib/graphql/decorators/user';

import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from '../../../paths';

import toast from 'react-hot-toast';
import EmptyState from '../../../components/EmptyState';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';

import { faExclamationCircle, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../../components/Icon';

export default function ConnectBookingProviderPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const provider = searchParams.get('provider');
  const code = searchParams.get('code');
  const searchParamsValid = provider === 'SQUARE' && !!code;
  const humanizedProvider = searchParamsValid ? UserDecorator.bookingProvider(provider) : null;

  const [connect, { data, error }] = useMutation<Data, Variables>(BOOKING_PROVIDER_CONNECT);

  useEffect(() => {
    if (!searchParamsValid) { return; }

    connect({
      variables: {
        input: { provider, code: code! },
      },
      onError: ({ graphQLErrors }) => {
        if (graphQLErrors[0]?.extensions?.code) {
          toast.remove();
        }
      },
    });
  }, [connect, searchParamsValid, provider, code]);

  const containerInnerClassName = 'max-w-2xl px-4';
  const errorIcon = (
    <Icon
      icon={faExclamationCircle}
      className="h-8 w-8 text-red-500 mx-auto"
    />
  );

  const continueButton = (
    <Button color="black" onClick={() => navigate(paths.root)}>
      Continue
    </Button>
  );

  if (!searchParamsValid) {
    return (
      <EmptyState
        innerClassName={containerInnerClassName}
        topContent={errorIcon}
        title="Something went wrong..."
        text="The authorization didn't seem to go through."
        buttons={continueButton}
      />
    );
  }

  if (error?.graphQLErrors[0]?.extensions?.code) {
    return (
      <EmptyState
        innerClassName={containerInnerClassName}
        topContent={errorIcon}
        title={`Couldn't connect to ${humanizedProvider}.`}
        text={error?.graphQLErrors[0]?.message}
        buttons={continueButton}
      />
    );
  }

  // If booking provider is set, show success message.
  if (data?.bookingProviderConnect.user.bookingProvider != null) {
    return (
      <EmptyState
        innerClassName={containerInnerClassName}
        topContent={(
          <Icon
            icon={faCircleCheck}
            className="h-8 w-8 text-green-500 mx-auto"
          />
        )}
        title={`Connected to ${humanizedProvider}!`}
        text="We'll automatically keep your MyPrices account in sync with your Square account. Your customers can now book appointments on your website through Square."
        buttons={continueButton}
      />
    );
  }

  return (
    <Loading
      text={`Connecting to ${humanizedProvider}`}
      className="h-full"
    />
  );
}
