import { gql } from '@apollo/client';
import { DOMAIN_FIELDS } from '../../fragments/domain';
import { Domain } from '../../entities/domain';

export const DOMAIN_CREATE = gql`
  ${DOMAIN_FIELDS}

  mutation DomainCreate($input: DomainCreateInput!) {
    domainCreate(input: $input) {
      domain {
        ...DomainFields
      }
    }
  }
`;

export interface Variables {
  input: {
    name: string;
  };
}

export interface Data {
  domainCreate: {
    domain: Domain;
  };
}
