import React from 'react';

import { OpenPeriod, Day } from '../../../../../lib/graphql/entities/open-period';
import OpenPeriodDecorator from '../../../../../lib/graphql/decorators/open-period';

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../../../../components/Icon';

interface Props {
  day: Day;
  periods: OpenPeriod[];
  onClick: () => void;
}

export default function DayCell({ day, periods, onClick }: Props) {
  return (
    <div
      className="cursor-pointer hover:bg-gray-50 flex items-center justify-between px-4 py-4 sm:px-6"
      onClick={onClick}
    >
      <div className="flex">
        <div>
          <p className="text-sm sm:text-base font-medium">
            {OpenPeriodDecorator.day(day)}
          </p>

          <p className="mt-0.5 sm:mt-0 text-xs sm:text-sm text-gray-500">
            {(() => {
              if (periods.length === 0) {
                return 'Closed';
              }

              return periods.map((p, key) => <span className="block" key={key}>{new OpenPeriodDecorator(p).timeRange}</span>);
            })()}
          </p>
        </div>
      </div>

      <div className="flex">
        <Icon icon={faChevronRight} className="text-gray-400" />
      </div>
    </div>
  );
}
