import React, { useRef } from 'react';
import { useAsync } from 'react-use';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_INITIAL_DATA } from '../../Root';
import { Query } from '../../lib/graphql/entities/query';
import {
  BOOKING_PROVIDER_DISCONNECT,
  Data as DisconnectData,
  Variables as DisconnectVariables,
} from '../../lib/graphql/mutations/booking-provider/disconnect';
import { BookingProvider } from '../../lib/graphql/entities/booking-provider';
import UserDecorator from '../../lib/graphql/decorators/user';

import { useLocation, useNavigate } from 'react-router-dom';
import paths from '../../paths';

import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import Loading from '../../components/Loading';
import EmptyState from '../../components/EmptyState';
import Icon from '../../components/Icon';
import Button from '../../components/Button';

export interface LocationState {
  validInitiation: boolean;
  removeData: boolean;
}

export default function DisconnectBookingProviderPage() {
  const locationState: LocationState | undefined = useLocation().state;
  const navigate = useNavigate();

  const initialBookingProvider = useRef<BookingProvider | null>(null);

  const [getInitialData, { data }] = useLazyQuery<Query>(GET_INITIAL_DATA);
  const [disconnect] = useMutation<DisconnectData, DisconnectVariables>(BOOKING_PROVIDER_DISCONNECT);

  useAsync(async () => {
    // Make sure the user navigated to this page legitimately.
    if (!locationState?.validInitiation) { return navigate(paths.root); }

    const { data } = await getInitialData();

    // Make sure the user is connected to a booking provider.
    if (data!.viewer!.bookingProvider == null) {
      return navigate(paths.root);
    } else {
      initialBookingProvider.current = data!.viewer!.bookingProvider;
    }

    // Start disconnecting from the booking provider.
    await disconnect({
      variables: {
        input: { removeData: locationState!.removeData },
      },
    });

    // Poll until the user is no longer connected to a booking provider.
    getInitialData({
      fetchPolicy: 'network-only',
      pollInterval: 2000,
    });
  }, [locationState, navigate, getInitialData, disconnect]);

  if (data == null || initialBookingProvider.current == null) { return null; }
  const humanizedProvider = UserDecorator.bookingProvider(initialBookingProvider.current);

  if (data.viewer!.bookingProvider == null) {
    return (
      <EmptyState
        innerClassName="max-w-2xl px-4"
        topContent={(
          <Icon
            icon={faCircleCheck}
            className="h-8 w-8 text-green-500 mx-auto"
          />
        )}
        title={`Disconnected from ${humanizedProvider}.`}
        text={`We will no longer sync with ${humanizedProvider}. Online booking has been disabled.`}
        buttons={(
          <Button color="black" onClick={() => navigate(paths.root)}>
            Continue
          </Button>
        )}
      />
    );
  }

  return (
    <Loading
      text={`Disconnecting from ${humanizedProvider}`}
      className="h-full"
    />
  );
}
