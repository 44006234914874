import { gql } from '@apollo/client';

const THEME_SETTING_DATA_FIELDS = `
  ... on ThemeSettingDataColor {
    value
  }
  ... on ThemeSettingDataMedia {
    type
    url
  }
  ... on ThemeSettingDataText {
    value
  }
`;

export const THEME_SETTING_FIELDS = gql`
  fragment ThemeSettingFields on ThemeSetting {
    id
    data {
      ${THEME_SETTING_DATA_FIELDS}
    }
    type
    label
    default {
      ${THEME_SETTING_DATA_FIELDS}
    }
  }
`;
