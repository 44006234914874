import React, { useEffect, useRef, useImperativeHandle } from 'react';

import { Variables as CreateVariables } from '../../../../lib/graphql/mutations/theme/file/create';
import { ThemeFileFolder } from '../CodeEditorPage';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import FieldContainer from '../../../../components/fields/FieldContainer';
import InputField, { SelectAddon } from '../../../../components/fields/InputField';

import { Dialog } from '@headlessui/react';
import { faFileCirclePlus } from '@fortawesome/pro-light-svg-icons';
import Icon from '../../../../components/Icon';
import Modal, { Props as ModalProps } from '../../../../components/Modal';
import PillTabs from '../../../../components/PillTabs';
import EmptyState from '../../../../components/EmptyState';
import Button from '../../../../components/Button';

import _ from 'lodash';
import { singular } from 'pluralize';
import classNames from 'classnames';

interface Props extends ModalProps {
  folder?: ThemeFileFolder;

  onSubmit: (values: CreateVariables['input']) => void;
  submitting: boolean;
}

interface FormValues {
  filename: string;
  extension: string;
  asset: FileList | null;
}

export default function CreateFileModal({ folder, onSubmit, submitting, ...props }: Props) {
  const { reset, handleSubmit, register, watch, formState } = useForm<FormValues>({
    resolver: zodResolver(
      z.object({
        asset: z.any().nullish(),
        filename: z.string().nullish(),
        extension: z.string().nullish(),
      })
        .superRefine(({ asset, filename }, context) => {
          if (_.isEmpty(asset) && _.isEmpty(filename)) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please select a file.',
              path: ['asset'],
            });

            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please enter a valid file name.',
              path: ['filename'],
            });
          }
        }),
    ),
  });

  useEffect(() => {
    reset();
  }, [props.open, reset]);

  const assetInputRef = useRef<HTMLInputElement>(null);
  const { ref: assetInputRefCallback, ...assetInputRegister } = register('asset');
  useImperativeHandle(assetInputRefCallback, () => assetInputRef.current);

  const assetFile = watch('asset')?.item(0);

  const renderForm = () => {
    if (folder === 'assets') {
      return (
        <div className="mt-2">
          <PillTabs
            fullwidth={true}
            panelClassName="pt-4"
            onChange={() => reset()}
            tabs={[
              {
                key: 'binary',
                title: 'Upload a file',
                render: () => (
                  <FieldContainer
                    onClick={() => assetInputRef.current!.click()}
                    error={formState.errors.asset && (
                      <span data-testid="create-file-modal-file-input-error">
                        {formState.errors.asset.message}
                      </span>
                    )}
                  >
                    <input
                      ref={assetInputRef}
                      className="hidden"
                      type="file"
                      {...assetInputRegister}
                      accept="image/*,video/*,.pdf,.css,.js,.json"
                      data-testid="create-file-modal-file-input"
                    />

                    <EmptyState
                      className={classNames('border border-gray-200 p-12 rounded-lg cursor-pointer hover:border-gray-300 hover:shadow-sm', {
                        'border-red-200 hover:border-red-300': formState.errors.asset?.message,
                      })}
                      topContent={(
                        <Icon
                          className="text-gray-400"
                          icon={faFileCirclePlus}
                          size="2x"
                        />
                      )}
                      title={(
                        <span data-testid="create-file-modal-file-input-title">
                          {assetFile && (
                            <span>
                              Ready to upload <code>{assetFile.name}</code>
                            </span>
                          )}

                          {!assetFile && <span>Upload a file</span>}
                        </span>
                      )}
                      text={(
                        <span data-testid="create-file-modal-file-input-text">
                          {assetFile && (
                            <span>
                              You may select a different file if you wish.
                            </span>
                          )}

                          {!assetFile && (
                            <span>
                              You may upload common file types <br /> such as images, videos, &amp; PDFs.
                            </span>
                          )}
                        </span>
                      )}
                    />
                  </FieldContainer>
                ),
                'data-testid': 'create-file-modal-binary-tab',
              },
              {
                key: 'text',
                title: 'Create an empty file',
                render: () => (
                  <div>
                    <InputField
                      label="File name"
                      placeholder="example"
                      {...register('filename')}
                      type="text"
                      required
                      error={formState.errors.filename?.message}
                      className="pr-16"
                      rightSelectAddon={(
                        <SelectAddon
                          {...register('extension')}
                          label="File extension"
                        >
                          <option value=".css">.css</option>
                          <option value=".js">.js</option>
                          <option value=".json">.json</option>
                        </SelectAddon>
                      )}
                    />
                  </div>
                ),
                'data-testid': 'create-file-modal-text-tab',
              },
            ]}
          />
        </div>
      );
    }

    return (
      <div className="mt-4">
        <input
          type="hidden"
          {...register('extension', { value: '.liquid' })}
        />

        <InputField
          label="File name"
          placeholder="example"
          {...register('filename')}
          type="text"
          required
          error={formState.errors.filename?.message}
          className="pr-16"
          rightAddon={<span className="text-gray-500 text-xs font-mono">.liquid</span>}
        />
      </div>
    );
  };

  return (
    <Modal {...props} className="max-w-xl">
      <form
        onSubmit={handleSubmit(async (values) => {
          const assetFile = values.asset?.item(0);
          const isTextFile = assetFile?.type.startsWith('text/') || assetFile?.type === 'application/json';

          let input: CreateVariables['input'];
          if (assetFile && !isTextFile) {
            input = {
              key: `${folder}/${assetFile.name}`,
              fileType: 'BINARY',
              content: null,
              asset: assetFile,
            };
          } else if (assetFile && isTextFile) {
            input = {
              key: `${folder}/${assetFile.name}`,
              fileType: 'TEXT',
              content: await assetFile.text(),
              asset: null,
            };
          } else {
            input = {
              key: `${folder}/${values.filename}${values.extension}`,
              fileType: 'TEXT',
              content: '',
              asset: null,
            };
          }

          onSubmit(input);
        })}
      >
        <div>
          <Dialog.Title className="text-base font-medium leading-6 text-gray-900" data-testid="create-file-modal-title">
            Add a new {folder ? singular(folder) : 'file'}
          </Dialog.Title>
        </div>

        <div>
          {renderForm()}
        </div>

        <div className="mt-4 flex gap-x-2">
          <Button
            className="flex-1"
            color="white"
            onClick={props.onClose}
          >
            Cancel
          </Button>

          <Button
            color="green"
            className="flex-1"
            type="submit"
            loading={submitting}
            disabled={submitting || !formState.isDirty}
            data-testid="create-file-modal-submit"
          >
            Add file
          </Button>
        </div>
      </form>
    </Modal>
  );
}
