import React, { useState } from 'react';

import { faPhotoFilm, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import FieldContainer, { Props as FieldContainerProps } from '../FieldContainer';
import Button from '../../Button';
import Icon from '../../Icon';
import MediaFieldModal from './Modal';

type MediaType = 'IMAGE' | 'VIDEO';
export interface MediaValue {
  type: MediaType;
  url: string;
};

interface Props extends FieldContainerProps {
  defaultValue?: MediaValue;
  onChange?: (value: MediaValue) => void;
}

export default function MediaField({ id, label, error, help, defaultValue, onChange: onChangeCallback }: Props) {
  const [value, setValue] = useState(defaultValue);
  const [modalOpen, setModalOpen] = useState(false);

  const onChange = (value: MediaValue) => {
    setValue(value);
    onChangeCallback?.(value);
  };

  return (
    <FieldContainer id={id} label={label} error={error} help={help} data-testid="media-field">
      {(() => {
        if (value == null) {
          return (
            <Button
              onClick={() => setModalOpen(true)}
              color="white"
              className="w-full sm:w-44"
              leftIcon={faPhotoFilm}
            >
              Choose file
            </Button>
          );
        }

        return (
          <div
            onClick={() => setModalOpen(true)}
            className="relative h-56 w-full sm:w-2/3 md:w-full xl:w-2/3 max-w-full border rounded-lg shadow-sm overflow-hidden cursor-pointer"
          >
            {(() => {
              switch (value.type) {
                case 'IMAGE':
                  return (
                    <img
                      src={value.url}
                      className="size-full object-cover pointer-events-none select-none rounded-b-lg"
                      alt=""
                      data-testid="media-field-image"
                    />
                  );
                case 'VIDEO':
                  return (
                    <video
                      src={value.url}
                      autoPlay loop muted playsInline
                      className="size-full object-cover pointer-events-none select-none rounded-b-lg"
                      data-testid="media-field-video"
                    />
                  );
              }
            })()}

            <div className="absolute inset-x-0 bottom-0 flex items-center justify-between py-2 px-4 bg-white/90 backdrop-blur">
              <div>
                <span className="text-sm select-none">Change file</span>
              </div>

              <div>
                <Icon icon={faChevronRight} className="h-3" />
              </div>
            </div>
          </div>
        );
      })()}

      <MediaFieldModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={(value) => {
          onChange(value);
          setModalOpen(false);
        }}
      />
    </FieldContainer>
  );
}
