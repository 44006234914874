import React from 'react';

import { Link } from 'react-router-dom';
import paths from '../../paths';

import classNames from 'classnames';

interface Props {
  to?: string;
  className?: string;
}

export default function Logo({ to = paths.root, className }: Props) {
  return (
    <Link to={to!}>
      <span className="sr-only">MyPrices</span>
      <img
        className={classNames('h-12 w-auto', className)}
        src="https://static.myprices.io/images/logo-text.png"
        alt="MyPrices logo"
      />
    </Link>
  );
}
