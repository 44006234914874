import { gql } from '@apollo/client';

export const THEME_FILE_FIELDS = gql`
  fragment ThemeFileFields on ThemeFile {
    id
    key
    fileType
    content
    asset
    protected
  }
`;
