import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { GET_INITIAL_DATA } from '../../Root';
import { Query } from '../../lib/graphql/entities/query';

import { useNavigate } from 'react-router-dom';
import paths from '../../paths';

import Loading from '../../components/Loading';

export default function StripeCallbackPage() {
  const navigate = useNavigate();
  const { data } = useQuery<Query>(GET_INITIAL_DATA, {
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });

  useEffect(() => {
    if (data == null) { return; }

    const { billing } = data.viewer!;
    if (billing == null) { return; }

    navigate(billing.plan === 'PRO' ? paths.onboarding.bookingProvider : paths.onboarding.businessInformation);
  }, [data, navigate]);

  return <Loading className="h-full" />;
}
