import { gql } from '@apollo/client';

export const OPEN_PERIOD_FIELDS = gql`
  fragment OpenPeriodFields on OpenPeriod {
    day
    start
    startFormatted
    end
    endFormatted
  }
`;
