import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_CONCIERGE_REQUESTS } from './ConciergePage';
import { Query } from '../../../lib/graphql/entities/query';
import ConciergeRequestDecorator from '../../../lib/graphql/decorators/concierge-request';
import BaseDecorator from '../../../lib/graphql/decorators/base';

import { useNavigate, useParams } from 'react-router-dom';
import paths from '../../../paths';

import { faFaceMelting } from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlassChart, faLaptopCode, faCircleCheck, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import MainLayout from '../../../layouts/MainLayout';
import Loading from '../../../components/Loading';
import EmptyState from '../../../components/EmptyState';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import StatusBadge from './components/StatusBadge';

import { Crisp } from 'crisp-sdk-web';

export default function ConciergeRequestPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, data } = useQuery<Query>(GET_CONCIERGE_REQUESTS);

  if (loading) { return <Loading className="h-full" />; }

  const request = data!.viewer!.conciergeRequests!.find((request) => request.id === id);
  if (request == null) {
    return (
      <EmptyState
        topContent={(
          <Icon
            icon={faFaceMelting}
            className="h-8 w-8 mx-auto"
          />
        )}
        title="Couldn't find this concierge request..."
        text="Are you sure the link is correct?"
        buttons={(
          <Button onClick={() => navigate(paths.website.concierge.index)} size="sm">
            Go to Concierge
          </Button>
        )}
        data-testid="concierge-request-not-found"
      />
    );
  }

  const title = `Request ${new ConciergeRequestDecorator(request).referenceNumber}`;
  return (
    <MainLayout
      backPath={paths.website.concierge.index}
      title={(
        <div className="flex items-center">
          <span data-testid="concierge-request-title">{title}</span>
          <StatusBadge
            conciergeRequest={request}
            className="ml-4"
            data-testid="concierge-request-status-badge"
          />
        </div>
      )}
      metaTitle={title}
      text={(
        <span data-testid="concierge-request-date">
          Made {BaseDecorator.date({ value: request.createdAt, withTime: true })}.
        </span>
      )}
      buttons={(
        <Button color="black" onClick={() => Crisp.chat.open()}>
          Chat with us
        </Button>
      )}
    >
      <div className="space-y-4 mt-2">
        <div>
          <span className="block text-sm font-medium text-gray-700 mb-1">Description</span>
          <div className="border border-gray-200 rounded-md shadow-sm p-4">
            <p className="whitespace-pre-wrap break-words" data-testid="concierge-request-description">
              {request.description}
            </p>
          </div>
        </div>

        <div>
          <span className="block text-sm font-medium text-gray-700 mb-1">Status</span>
          <div className="border border-gray-200 rounded-md shadow-sm px-4 py-8 sm:py-12">
            {(() => {
              const icon = (() => {
                switch (request.status) {
                  case 'PENDING':
                    return (
                      <Icon
                        icon={faMagnifyingGlassChart}
                        className="h-8 w-8 mx-auto"
                        data-testid="concierge-request-status-pending-icon"
                      />
                    );
                  case 'IN_PROGRESS':
                    return (
                      <Icon
                        icon={faLaptopCode}
                        className="h-8 w-8 mx-auto"
                        data-testid="concierge-request-status-in-progress-icon"
                      />
                    );
                  case 'COMPLETED':
                    return (
                      <Icon
                        icon={faCircleCheck}
                        className="h-8 w-8 text-green-600 mx-auto"
                        data-testid="concierge-request-status-completed-icon"
                      />
                    );
                  case 'CANCELED':
                    return (
                      <Icon
                        icon={faExclamationCircle}
                        className="h-8 w-8 text-red-500 mx-auto"
                        data-testid="concierge-request-status-canceled-icon"
                      />
                    );
                }
              })();

              const title = (() => {
                switch (request.status) {
                  case 'PENDING':
                    return 'Reviewing your request...';
                  case 'IN_PROGRESS':
                    return 'Working on your request!';
                  case 'COMPLETED':
                    return "We've completed your request!";
                  case 'CANCELED':
                    return 'This request was canceled.';
                }
              })();

              const text = (() => {
                switch (request.status) {
                  case 'PENDING':
                    return "We'll reach out to you shortly.";
                  case 'IN_PROGRESS':
                    return "We'll let you know when we're done.";
                  case 'COMPLETED':
                  case 'CANCELED':
                    return 'Feel free to reach out if you have any questions.';
                }
              })();

              return (
                <EmptyState
                  topContent={icon}
                  title={<span data-testid="concierge-request-status-title">{title}</span>}
                  text={<span data-testid="concierge-request-status-text">{text}</span>}
                />
              );
            })()}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
