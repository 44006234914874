import React, { useRef, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Query } from '../../../lib/graphql/entities/query';
import { USER_FIELDS } from '../../../lib/graphql/fragments/user';
import { DOMAIN_FIELDS } from '../../../lib/graphql/fragments/domain';
import { THEME_FIELDS } from '../../../lib/graphql/fragments/theme';
import { THEME_SETTING_FIELDS } from '../../../lib/graphql/fragments/theme/setting';
import { OPEN_PERIOD_FIELDS } from '../../../lib/graphql/fragments/open-period';

import { useLocation } from 'react-router-dom';

import MainLayout from '../../../layouts/MainLayout';
import DomainSection from './components/DomainSection';
import ThemeSettingsSection from './components/ThemeSettingsSection';
import ExternalLinksSection from '../../settings/components/ExternalLinksSection';
import OpenPeriodsSection from '../../settings/components/OpenPeriodsSection';

export const GET_CUSTOMIZATION_DATA = gql`
  ${USER_FIELDS}
  ${DOMAIN_FIELDS}
  ${THEME_FIELDS}
  ${THEME_SETTING_FIELDS}
  ${OPEN_PERIOD_FIELDS}

  query GetCustomizationData {
    viewer {
      ...UserFields
      domain {
        ...DomainFields
      }
      theme {
        ...ThemeFields
        settings {
          ...ThemeSettingFields
        }
      }
      openPeriods {
        ...OpenPeriodFields
      }
    }
  }
`;

export interface LocationState {
  section?: 'open-periods';
}

export default function CustomizePage() {
  const locationState: LocationState | undefined = useLocation().state;

  const { data, loading } = useQuery<Query>(GET_CUSTOMIZATION_DATA);

  const openPeriodsSection = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (data == null) { return; }

    if (locationState?.section === 'open-periods') {
      openPeriodsSection.current!.scrollIntoView({ behavior: 'smooth' });
    }
  }, [locationState, data]);

  return (
    <MainLayout
      title="Customize your website"
      text="Add a custom domain & tweak your website settings here."
      loading={loading}
    >
      <div className="space-y-10 divide-y divide-gray-900/10">
        <DomainSection user={data?.viewer!} />
        <ThemeSettingsSection user={data?.viewer!} />
        <ExternalLinksSection user={data?.viewer!} />
        <OpenPeriodsSection user={data?.viewer!} ref={openPeriodsSection} />
      </div>
    </MainLayout>
  );
}
