import React from 'react';

import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../../../../components/Modal';
import Button from '../../../../components/Button';

interface Props extends ModalProps {
  onConfirm: () => void;
}

export default function SkipConfirmationModal({ onConfirm, ...props }: Props) {
  return (
    <Modal {...props}>
      <div>
        <Dialog.Title className="text-center text-lg font-medium leading-6 text-gray-900 mt-2">
          Are you sure?
        </Dialog.Title>

        <Dialog.Description className="text-center text-sm text-gray-500 mt-2">
          You'll have to add your business details manually &amp; online booking won't be available on your website.
        </Dialog.Description>
      </div>

      <div className="mt-4 flex gap-x-2">
        <Button
          className="flex-1"
          color="white"
          onClick={props.onClose}
        >
          Cancel
        </Button>

        <Button
          color="black"
          className="flex-1"
          onClick={onConfirm}
          data-testid="skip-confirmation-modal-submit"
        >
          Yes, I'm sure
        </Button>
      </div>
    </Modal>
  );
}
