import React from 'react';

import { UnsplashPhoto } from '../../../../../lib/graphql/entities/unsplash/photo';

import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../../Icon';

interface Props {
  photo: UnsplashPhoto;

  selected: boolean;
  onSelect: (photo: UnsplashPhoto) => void;
}

export default function Photo({ photo, selected, onSelect }: Props) {
  return (
    <div
      onClick={() => onSelect(photo)}
      className="border rounded-lg shadow-sm overflow-hidden cursor-pointer flex flex-col"
      data-testid="photo"
    >
      <div className="relative">
        <img
          src={photo.source}
          loading="lazy"
          className="object-cover pointer-events-none select-none aspect-video"
          alt=""
        />

        {selected && (
          <div className="absolute inset-0 bg-black/25 flex items-center justify-center">
            <Icon icon={faCheckCircle} className="text-white text-3xl" data-testid="selected-icon" />
          </div>
        )}
      </div>

      <div className="flex-1 flex items-center py-2 px-3 bg-white text-xs">
        <a href={`${photo.user.url}?utm_source=MyPrices&utm_medium=referral`} target="_blank" rel="noreferrer" data-testid="attribution">
          By <span className="font-medium">{photo.user.name}</span> on <span className="font-medium">Unsplash</span>
        </a>
      </div>
    </div>
  );
}
