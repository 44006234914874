import React from 'react';

import classNames from 'classnames';

interface Props {
  className?: string;
  innerClassName?: string;

  topContent?: React.ReactNode;
  title: React.ReactNode;
  text: React.ReactNode;
  buttons?: React.ReactNode;

  'data-testid'?: string;
}

export default function EmptyState({ className, innerClassName, topContent, title, text, buttons, 'data-testid': dataTestId }: Props) {
  return (
    <div className={classNames('h-full flex justify-center items-center px-4', className)} data-testid={dataTestId}>
      <div className={classNames('text-center', innerClassName)}>
        {topContent && <div>{topContent}</div>}

        <h3
          className={classNames('text-base font-medium text-gray-900', {
            'mt-2': topContent != null,
          })}
        >
          {title}
        </h3>

        <p className="mt-1 text-sm text-gray-500">{text}</p>

        {buttons && (
          <div className="mt-6 flex justify-center gap-x-2">
            {buttons}
          </div>
        )}
      </div>
    </div>
  );
}
