import React from 'react';

import { ConciergeRequest } from '../../../../lib/graphql/entities/concierge-request';
import ConciergeRequestDecorator from '../../../../lib/graphql/decorators/concierge-request';

import Badge, { Props as BadgeProps } from '../../../../components/Badge';

interface Props extends Omit<BadgeProps, 'children'> {
  conciergeRequest: ConciergeRequest;
}

export default function StatusBadge({ conciergeRequest, ...props }: Props) {
  const color: BadgeProps['color'] = (() => {
    switch (conciergeRequest.status) {
      case 'PENDING':
        return 'gray';
      case 'IN_PROGRESS':
        return 'blue';
      case 'COMPLETED':
        return 'green';
      case 'CANCELED':
        return 'red';
    }
  })();

  return (
    <Badge color={color} {...props}>
      {new ConciergeRequestDecorator(conciergeRequest).status}
    </Badge>
  );
}
