import React from 'react';

import { Country } from '../../../lib/graphql/entities/country';

import InputField, { Props as InputFieldProps } from '../InputField';

interface Props extends InputFieldProps {
  country: Country;
}

const CityField = React.forwardRef<HTMLInputElement, Props>(({ country, ...props }, ref) => {
  const label = (() => {
    switch (country) {
      case 'US':
      case 'CA':
      case 'GB':
        return 'City';
      case 'AU':
        return 'Suburb';
    }
  })();

  const placeholder = (() => {
    switch (country) {
      case 'US':
        return 'Los Angeles';
      case 'CA':
        return 'Vancouver';
      case 'GB':
        return 'London';
      case 'AU':
        return 'Sydney';
    }
  })();

  return (
    <InputField
      ref={ref}
      label={label}
      placeholder={placeholder}
      type="text"
      {...props}
    />
  );
});

export default CityField;
