import React from 'react';

import { useNavigate } from 'react-router-dom';
import paths from '../../../../../paths';

import TaskCell from '../TaskCell';

interface Props {
  completed: boolean;
}

export default function AddDomain({ completed }: Props) {
  const navigate = useNavigate();

  return (
    <TaskCell
      title="Connect a domain"
      text="Having a custom domain is essential to building your brand."
      completed={completed}
      onClick={() => navigate(paths.website.customize)}
      data-testid="add-domain-task-cell"
    />
  );
}
