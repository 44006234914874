import React from 'react';

import { ThemeFile } from '../../../../lib/graphql/entities/theme/file';

import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../../../../components/Modal';
import Button from '../../../../components/Button';

interface Props extends ModalProps {
  file?: ThemeFile;
  onDelete: () => void;
  deleting: boolean;
}

export default function DeleteFileModal({ file, onDelete, deleting, ...props }: Props) {
  return (
    <Modal {...props}>
      <div>
        <Dialog.Title className="text-base font-medium leading-6 text-gray-900" data-testid="delete-file-modal-title">
          Delete <code>{file?.key ?? 'file'}</code>?
        </Dialog.Title>

        <Dialog.Description className="text-sm text-gray-500 mt-2">
          Deleting this file will break any other files that still reference it.
          This action cannot be undone.
        </Dialog.Description>
      </div>

      <div className="mt-4 flex gap-x-2">
        <Button
          className="flex-1"
          color="white"
          onClick={props.onClose}
        >
          Cancel
        </Button>

        <Button
          color="red"
          className="flex-1"
          onClick={onDelete}
          loading={deleting}
          disabled={deleting}
          data-testid="delete-file-modal-submit"
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
}
