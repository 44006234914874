import React from 'react';

import { useMutation } from '@apollo/client';
import { AsStoreObject } from '@apollo/client/utilities';
import { DOMAIN_DELETE, Data as DeleteData } from '../../../../../../lib/graphql/mutations/domain/delete';
import { Domain } from '../../../../../../lib/graphql/entities/domain';

import toast from 'react-hot-toast';
import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';

interface Props extends ModalProps {
  domain: Domain;
  onDeleted: () => void;
}

export default function DeleteDomainModal({ domain, ...props }: Props) {
  const [deleteDomain, { loading: deleting }] = useMutation<DeleteData>(DOMAIN_DELETE, {
    update: (cache) => {
      toast.success(`${domain.name} has been unlinked.`);

      cache.evict({ id: cache.identify(domain as AsStoreObject<Domain>) });
      props.onDeleted();
    },
  });

  return (
    <Modal {...props} className="mx-4 sm:mx-0" data-testid="delete-domain-modal">
      <div>
        <Dialog.Title className="text-base font-medium leading-6 text-gray-900" data-testid="delete-domain-modal-title">
          Unlink {domain.name}?
        </Dialog.Title>

        <Dialog.Description className="text-sm text-gray-500 mt-2" data-testid="delete-domain-modal-description">
          Are you sure you want to unlink this domain? Your website will no longer be accessible from {domain.name}.
        </Dialog.Description>
      </div>

      <div className="mt-4 flex gap-x-2">
        <Button
          className="flex-1"
          color="white"
          onClick={props.onClose}
        >
          Cancel
        </Button>

        <Button
          className="flex-1"
          color="red"
          onClick={() => deleteDomain()}
          loading={deleting}
          disabled={deleting}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
}
