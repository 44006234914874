export interface ThemeSetting {
  __typename: 'ThemeSetting';
  id: string;
  data: ThemeSettingDataColor | ThemeSettingDataMedia | ThemeSettingDataText;
  type: ThemeSettingType;
  label: string;
  default: ThemeSettingDataColor | ThemeSettingDataMedia | ThemeSettingDataText;
}

export interface ThemeSettingDataColor {
  __typename: 'ThemeSettingDataColor';
  value: string;
}

export interface ThemeSettingDataMedia {
  __typename: 'ThemeSettingDataMedia';
  type: ThemeSettingDataMediaType;
  url: string;
}

export const THEME_SETTING_DATA_MEDIA_TYPES = ['IMAGE', 'VIDEO'] as const;
export type ThemeSettingDataMediaType = typeof THEME_SETTING_DATA_MEDIA_TYPES[number];

export interface ThemeSettingDataText {
  __typename: 'ThemeSettingDataText';
  value: string;
}

export type ThemeSettingType = 'COLOR' | 'MEDIA' | 'TEXT';
