import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import App from './App';
import reportWebVitals from './report-web-vitals';
import { SENTRY_DSN } from './lib/constants';

import './assets/styles/index.css';

import * as Sentry from '@sentry/react';

import { Crisp } from 'crisp-sdk-web';
import { CRISP_WEBSITE_ID } from './lib/constants';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

Sentry.init({
  dsn: SENTRY_DSN,
  debug: (process.env.REACT_APP_ENVIRONMENT as ReactAppEnvironment) === 'development',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
});

Crisp.configure(CRISP_WEBSITE_ID);

switch (process.env.REACT_APP_ENVIRONMENT as ReactAppEnvironment) {
  case 'staging':
  case 'production':
    Crisp.setSafeMode(true);
    break;
}

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
