import React from 'react';

import { Country } from '../../../lib/graphql/entities/country';

import InputField, { Props as InputFieldProps } from '../InputField';

interface Props extends InputFieldProps {
  country: Country;
}

const PostalCodeField = React.forwardRef<HTMLInputElement, Props>(({ country, ...props }, ref) => {
  const label = (() => {
    switch (country) {
      case 'US':
        return 'ZIP code';
      case 'CA':
        return 'Postal code';
      case 'GB':
      case 'AU':
        return 'Postcode';
    }
  })();

  const placeholder = (() => {
    switch (country) {
      case 'US':
        return '90015';
      case 'CA':
        return 'V6B 5A6';
      case 'GB':
        return 'E14 5RE';
      case 'AU':
        return '2000';
    }
  })();

  return (
    <InputField
      ref={ref}
      label={label}
      placeholder={placeholder}
      type="text"
      {...props}
    />
  );
});

export default PostalCodeField;
