import { gql } from '@apollo/client';

export const CONCIERGE_REQUEST_FIELDS = gql`
  fragment ConciergeRequestFields on ConciergeRequest {
    id
    description
    status
    referenceNumber
    createdAt
  }
`;
