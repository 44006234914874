import React, { useRef } from 'react';

import { useMutation } from '@apollo/client';
import {
  USER_UPDATE,
  Data as UpdateData,
  Variables as UpdateVariables,
} from '../../../lib/graphql/mutations/user/update';
import { User } from '../../../lib/graphql/entities/user';

import toast from 'react-hot-toast';
import { faStore } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';

interface Props {
  user: User;
}

export default function LogoSection({ user }: Props) {
  const fileInput = useRef<HTMLInputElement>(null);
  const [updateUser] = useMutation<UpdateData, UpdateVariables>(USER_UPDATE);

  const onClickRemove = () => {
    updateUser({
      variables: {
        input: { logo: null },
      },
      update: () => {
        toast.success('Logo removed.');
      },
    });
  };
  const onClickChange = () => fileInput.current!.click();

  const onChangeFile = (file: File) => {
    updateUser({
      variables: {
        input: { logo: file },
      },
      update: () => {
        toast.success('Logo updated.');
      },
    });
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3">
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Logo</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          This logo will be displayed on your website.
        </p>
      </div>

      <form className="bg-white py-6 flex items-center justify-center flex-col shadow-sm ring-1 ring-gray-900/5 rounded-md sm:rounded-xl lg:col-span-2">
        <input
          ref={fileInput}
          className="hidden"
          type="file"
          accept="image/*"
          onChange={(e) => onChangeFile(e.target.files!.item(0)!)}
          data-testid="logo-file-input"
        />

        <div className="relative">
          <div className="h-28 w-28 rounded-full border border-gray-200 flex items-center justify-center overflow-hidden">
            {(() => {
              if (user.logo) {
                return <img src={user.logo.url} alt="Your logo" data-testid="logo-current" />;
              }

              return <Icon icon={faStore} className="h-8 w-8" data-testid="logo-placeholder" />;
            })()}
          </div>

          {user.logo && (
            <button
              type="button"
              className="absolute top-0 right-0 bg-white hover:bg-gray-50 rounded-full p-2 flex items-center justify-center border border-gray-200"
              onClick={onClickRemove}
              data-testid="logo-remove-button"
            >
              <Icon icon={faXmark} className="h-3 w-3" />
            </button>
          )}
        </div>

        <Button
          rounded
          size="sm"
          color="white"
          className="mt-4"
          onClick={onClickChange}
          data-testid="logo-change-button"
        >
          {user.logo ? 'Change logo' : 'Add a logo'}
        </Button>
      </form>
    </div>
  );
}
