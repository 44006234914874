import { gql } from '@apollo/client';
import { SERVICE_FIELDS } from '../../fragments/service';
import { Service } from '../../entities/service';

export const SERVICE_CREATE = gql`
  ${SERVICE_FIELDS}

  mutation ServiceCreate($input: ServiceCreateInput!) {
    serviceCreate(input: $input) {
      service {
        ...ServiceFields
      }
    }
  }
`;

export interface Variables {
  input: {
    name: string;
    description: string;
    variations: Array<{
      name: string;
      duration: number;
      price: {
        type: 'FIXED' | 'VARIABLE';
        amount?: number | null;
        displayAs?: string | null;
      };
    }>;
  };
}

export interface Data {
  serviceCreate: {
    service: Service;
  };
}
