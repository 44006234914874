import { gql } from '@apollo/client';

export const SERVICE_FIELDS = gql`
  fragment ServiceFields on Service {
    id
    name
    description
    durationFormatted
    priceFormatted
    position
    provider
    providerUrl
    variations {
      id
      name
      duration
      price {
        type
        amount
        displayAs
      }
      position
      provider
    }
  }
`;
