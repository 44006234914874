import { gql } from '@apollo/client';

export const SEND_RESET_PASSWORD_LINK = gql`
  mutation SendResetPasswordLink($input: AuthSendResetPasswordLinkInput!) {
    authSendResetPasswordLink(input: $input) {
      success
    }
  }
`;

export interface Variables {
  input: {
    email: string;
  };
}

export interface Data {
  authSendResetPasswordLink: {
    success: true;
  };
}
