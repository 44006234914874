import React, { useRef } from 'react';

import { useMutation } from '@apollo/client';
import {
  THEME_FILE_CREATE,
  Data as ThemeFileCreateData,
  Variables as ThemeFileCreateVariables,
} from '../../../../lib/graphql/mutations/theme/file/create';

import { faFileCirclePlus } from '@fortawesome/pro-light-svg-icons';
import FieldContainer from '../../FieldContainer';
import EmptyState from '../../../EmptyState';
import Loading from '../../../Loading';
import Icon from '../../../Icon';

import { MediaValue } from '../';

interface Props {
  file?: File | null;
  onChange: (state: { file: File; value: MediaValue }) => void;
  reset: () => void;
}

export default function UploadTab({ file, onChange, reset }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [createThemeFile, { loading: uploading }] = useMutation<ThemeFileCreateData, ThemeFileCreateVariables>(THEME_FILE_CREATE);

  const onSelectFile = (selected: File) => {
    createThemeFile({
      variables: {
        input: {
          key: (() => {
            const timestamp = Date.now();
            const extension = selected.name.split('.').pop();

            return `assets/uploads-${timestamp}.${extension}`;
          })(),
          fileType: 'BINARY',
          asset: selected,
          content: null,
        },
      },
      onCompleted: (data) => {
        onChange({
          file: selected,
          value: {
            type: (() => {
              const fileType = selected.type.split('/')[0];

              switch (fileType) {
                case 'image': return 'IMAGE';
                case 'video': return 'VIDEO';
                default:
                  throw new Error('Invalid file type.');
              }
            })(),
            url: data.themeFileCreate.themeFile.asset!,
          },
        });
      },
      onError: () => reset(),
    });
  };

  return (
    <FieldContainer onClick={() => inputRef.current!.click()}>
      <input
        ref={inputRef}
        className="hidden"
        type="file"
        accept="image/*,video/*"
        onChange={(e) => onSelectFile(e.target.files![0])}
        data-testid="file-input"
      />

      <EmptyState
        className="border border-gray-200 p-12 rounded-lg cursor-pointer hover:border-gray-300 hover:shadow-sm"
        topContent={(() => {
          if (uploading) { return <Loading size={28} data-testid="loading-icon" />; }

          return (
            <Icon
              className="text-gray-400"
              icon={faFileCirclePlus}
              size="2x"
              data-testid="file-icon"
            />
          );
        })()}
        title={(
          <span data-testid="title">
            {(() => {
              if (uploading) { return 'Uploading...'; }
              if (file == null) { return 'Upload a file'; }

              return (
                <span>
                  Ready to upload <code>{file.name}</code>
                </span>
              );
            })()}
          </span>
        )}
        text={(
          <span data-testid="text">
            {(() => {
              if (uploading) { return 'This should only take a moment.'; }
              if (file == null) { return 'You may upload any image or video file.'; }

              return 'You may select a different file if you wish.';
            })()}
          </span>
        )}
      />
    </FieldContainer>
  );
}
