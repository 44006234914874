import React from 'react';

import { MoonLoader } from 'react-spinners';

import classNames from 'classnames';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * The size of the spinner. Must be evenly divisible by 7 due to a sizing bug.
   * @see https://github.com/davidhu2000/react-spinners/issues/583
   */
  size?: number;
  color?: string;
  text?: string;
}

export default function Loading({ size = 42, color = '#000000', text, ...props }: Props) {
  return (
    <div
      data-testid="loading-container"
      {...props}
      className={classNames('text-center flex flex-col items-center justify-center', props.className)}
    >
      <MoonLoader size={size} color={color} />
      {text && <span className="mt-4 text-base">{text}</span>}
    </div>
  );
}
