import React from 'react';

import { Tab } from '@headlessui/react';
import classNames from 'classnames';

interface Props {
  tabs: Array<{
    key: string;
    title: string;
    render: () => React.ReactNode;
    'data-testid'?: string;
  }>;
  selectedKey?: string;
  onChange?: (key: string) => void;
  fullwidth?: boolean;
  className?: string;
  panelClassName?: string;
}

export default function PillTabs({ tabs, selectedKey, onChange, fullwidth, className, panelClassName }: Props) {
  return (
    <div className={className}>
      <Tab.Group
        selectedIndex={selectedKey ? tabs.findIndex(({ key }) => key === selectedKey)! : undefined}
        onChange={(index) => {
          if (onChange) { onChange(tabs[index].key); }
        }}
      >
        <div className={classNames({ 'sm:flex sm:justify-center': !fullwidth })}>
          <Tab.List className="relative flex self-center rounded-lg bg-gray-100 p-0.5">
            {tabs.map(({ key, title, 'data-testid': dataTestId }, index) => (
              <Tab
                key={key}
                className={({ selected }) => classNames(
                  'relative w-1/2 whitespace-nowrap rounded-md text-sm font-medium py-2',
                  'focus:z-10 focus:outline-none sm:px-8 border',
                  {
                    'sm:w-auto': !fullwidth,
                    'border-gray-200 bg-white text-gray-900 shadow-sm': selected,
                    'border-transparent text-gray-700': !selected,
                    'ml-0.5': index !== 0,
                  },
                )}
                data-testid={dataTestId}
              >
                {title}
              </Tab>
            ))}
          </Tab.List>
        </div>

        <Tab.Panels>
          {tabs.map(({ key, render }) => (
            <Tab.Panel key={key} className={classNames('focus:outline-none', panelClassName)}>
              {render()}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
