import { gql } from '@apollo/client';

export const USER_CREATE_BILLING_PORTAL_URL = gql`
  mutation UserCreateBillingPortalURL($input: UserCreateBillingPortalURLInput!) {
    userCreateBillingPortalUrl(input: $input) {
      url
    }
  }
`;

export interface Variables {
  input: {
    flow?: 'SUBSCRIPTION_UPDATE' | null;
  };
}

export interface Data {
  userCreateBillingPortalUrl: {
    url: string;
  };
}
