import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_INITIAL_DATA } from '../../../../Root';
import { Query } from '../../../../lib/graphql/entities/query';

import { useNavigate } from 'react-router-dom';
import paths from '../../../../paths';
import type { LocationState } from '../../../../pages/auth/SignOutPage';

import { faStore } from '@fortawesome/pro-regular-svg-icons';
import { Menu, Transition } from '@headlessui/react';
import DropdownItem, { Props as DropdownItemProps } from './DropdownItem';
import Icon from '../../../../components/Icon';

import classNames from 'classnames';

interface Props {
  className?: string;
}

export default function AccountDropdown({ className }: Props) {
  const navigate = useNavigate();
  const { data, loading } = useQuery<Query>(GET_INITIAL_DATA);

  const items: DropdownItemProps[] = [
    {
      onClick: () => navigate(paths.auth.signOut, {
        state: { validInitiation: true } as LocationState,
      }),
      children: <span className="text-red-600">Sign out</span>,
    },
  ];

  if (loading) { return null; }
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className={classNames('max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none', className)}>
          <span className="sr-only">Open user menu</span>
          <span className="mx-3 hidden sm:block">{data!.viewer!.businessName}</span>
          <div className="h-8 w-8 rounded-full border border-gray-200 flex items-center justify-center overflow-hidden">
            {(() => {
              if (data!.viewer!.logo) {
                return <img src={data!.viewer!.logo.url} alt="Your logo" data-testid="logo" />;
              }

              return <Icon icon={faStore} className="h-4 w-4" data-testid="no-logo-icon" />;
            })()}
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-50">
          <DropdownItem to={paths.settings}>
            <div>{data!.viewer!.businessName}</div>
            <div className="text-xs text-gray-500 break-words">{data!.viewer!.email}</div>
          </DropdownItem>

          {items.map((item, index) => <DropdownItem key={index} {...item} />)}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
