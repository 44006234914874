import React from 'react';

import { useMutation } from '@apollo/client';
import {
  BOOKING_PROVIDER_CREATE_AUTHORIZATION_URL,
  Data as BookingProviderCreateAuthorizationURLData,
  Variables as BookingProviderCreateAuthorizationURLVariables,
} from '../../lib/graphql/mutations/booking-provider/create-authorization-url';
import { User } from '../../lib/graphql/entities/user';
import { BookingProvider } from '../../lib/graphql/entities/booking-provider';
import UserDecorator from '../../lib/graphql/decorators/user';

import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../Modal';
import { Content as ProRequiredContent } from '../../layouts/MainLayout/components/ProRequiredModal';
import Button from '../Button';

interface Props extends ModalProps {
  user: User;
  bookingProvider?: BookingProvider;
}

type ConnectBookingProviderModalState = (
  { open: true; bookingProvider: BookingProvider; }
    | { open: false; bookingProvider?: BookingProvider; }
);

type ConnectBookingProviderModalAction = (
  { type: 'OPEN'; bookingProvider: BookingProvider; }
    | { type: 'CLOSE'; }
);

export const connectBookingProviderModalReducer: React.Reducer<ConnectBookingProviderModalState, ConnectBookingProviderModalAction> = (state, action) => {
  switch (action.type) {
    case 'OPEN':
      return { open: true, bookingProvider: action.bookingProvider };
    case 'CLOSE':
      return { ...state, open: false };
  }
};

export default function ConnectBookingProviderModal({ user, bookingProvider, ...props }: Props) {
  const bookingProviderHumanized = bookingProvider ? UserDecorator.bookingProvider(bookingProvider) : null;

  const [createAuthorizationURL, createAuthorizationURLResult] = useMutation<
    BookingProviderCreateAuthorizationURLData,
    BookingProviderCreateAuthorizationURLVariables
  >(BOOKING_PROVIDER_CREATE_AUTHORIZATION_URL, {
    variables: {
      input: { provider: bookingProvider! },
    },
    update: (_, { data }) => {
      window.location.assign(data!.bookingProviderCreateAuthorizationUrl.url);
    },
  });

  const renderContent = () => {
    if (user.billing?.plan !== 'PRO') {
      return (
        <ProRequiredContent
          description={`You'll need a Pro plan to connect to ${bookingProviderHumanized}.`}
          {...props}
        />
      );
    }

    return (
      <div data-testid="connect-modal-content">
        {bookingProvider === 'SQUARE' &&
          <img
            className="w-10 h-10 mx-auto"
            src={require('../../assets/images/icons/square.png')}
            alt=""
          />
        }

        <Dialog.Title className="text-center text-lg font-medium leading-6 text-gray-900 mt-2">
          Connect to {bookingProviderHumanized}
        </Dialog.Title>

        <Dialog.Description className="text-center text-sm text-gray-500 mt-2">
          You'll be redirected to {bookingProviderHumanized}'s authorization page to connect to MyPrices.
        </Dialog.Description>

        <div className="mt-4 flex gap-x-2">
          <Button
            className="flex-1"
            color="white"
            onClick={props.onClose}
          >
            Close
          </Button>

          <Button
            className="flex-1"
            onClick={() => createAuthorizationURL()}
            loading={createAuthorizationURLResult.loading}
            disabled={createAuthorizationURLResult.loading}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal className="py-8" {...props}>
      {renderContent()}
    </Modal>
  );
}
