import React, { useState } from 'react';

import { User } from '../../lib/graphql/entities/user';
import UserDecorator from '../../lib/graphql/decorators/user';

import { useNavigate } from 'react-router-dom';
import paths from '../../paths';
import type { LocationState } from '../../pages/booking-provider/DisconnectBookingProviderPage';

import Switch from '../fields/Switch';

import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../Modal';
import Button from '../Button';

interface Props extends ModalProps {
  user: User;
}

export default function DisconnectBookingProviderModal({ user, open, onClose }: Props) {
  const navigate = useNavigate();
  const [removeData, setRemoveData] = useState(true);

  if (user.bookingProvider == null) { return null; }

  const bookingProviderHumanized = UserDecorator.bookingProvider(user.bookingProvider);
  return (
    <Modal className="py-8" open={open} onClose={onClose}>
      <div data-testid="disconnect-modal-content">
        {user.bookingProvider === 'SQUARE' &&
          <img
            className="w-10 h-10 mx-auto"
            src={require('../../assets/images/icons/square.png')}
            alt=""
          />
        }

        <Dialog.Title className="text-center text-lg font-medium leading-6 text-gray-900 mt-2">
          Disconnect from {bookingProviderHumanized}?
        </Dialog.Title>

        <Dialog.Description className="text-center text-sm text-gray-500 mt-2">
          We will no longer sync with {bookingProviderHumanized} &amp; your customers
          will not be able to book appointments online.
        </Dialog.Description>

        <div className="mt-4">
          <Switch
            id="remove-data"
            checked={removeData}
            onToggle={setRemoveData}
            data-testid="remove-data-switch"
          >
            Remove data that's already been synced with {bookingProviderHumanized}.
          </Switch>
        </div>

        <div className="mt-4 flex gap-x-2">
          <Button
            className="flex-1"
            color="white"
            onClick={onClose}
          >
            Close
          </Button>

          <Button
            className="flex-1"
            color="red"
            onClick={() => navigate(paths.bookingProvider.disconnect, {
              state: { validInitiation: true, removeData } as LocationState,
            })}
          >
            Disconnect
          </Button>
        </div>
      </div>
    </Modal>
  );
}
