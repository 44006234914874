import React from 'react';

import { ApolloProvider } from '@apollo/client';
import graphqlClient from './lib/graphql/client';

import * as Sentry from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import signedOutOnlyLoader from './lib/router/loaders/signed-out-only';
import signedInOnlyLoader from './lib/router/loaders/signed-in-only';
import completeLoader from './lib/router/loaders/complete';
import paths from './paths';

import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import Loading from './components/Loading';

import Root from './Root';
import ErrorPage from './pages/ErrorPage';
import ComingSoonPage from './pages/ComingSoonPage';

import SignInPage from './pages/auth/SignInPage';
import SignUpPage from './pages/auth/SignUpPage';
import SignOutPage from './pages/auth/SignOutPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import SendConfirmationLinkPage from './pages/auth/SendConfirmationLinkPage';
import ConfirmPage from './pages/auth/ConfirmPage';
import NotConfirmedPage from './pages/auth/NotConfirmedPage';
import notConfirmedPageLoader from './pages/auth/NotConfirmedPage/loader';
import DeleteAccountPage from './pages/auth/DeleteAccountPage';

import onboardingPageLoader from './pages/onboarding/loader';
import SelectPlanPage from './pages/onboarding/SelectPlanPage';
import StripeCallbackPage from './pages/onboarding/StripeCallbackPage';
import BookingProviderPage from './pages/onboarding/BookingProviderPage';
import BusinessInformationPage from './pages/onboarding/BusinessInformationPage';

import ConnectBookingProviderPage from './pages/booking-provider/ConnectBookingProviderPage';
import connectBookingProviderPageLoader from './pages/booking-provider/ConnectBookingProviderPage/loader';
import DisconnectBookingProviderPage from './pages/booking-provider/DisconnectBookingProviderPage';

import TrialEndedPage from './pages/subscription/TrialEndedPage';
import trialEndedPageLoader from './pages/subscription/TrialEndedPage/loader';

import DashboardPage from './pages/dashboard/DashboardPage';

import ServicesPage from './pages/services/ServicesPage';

import CodeEditorPage from './pages/website/code-editor/CodeEditorPage';
import CustomizePage from './pages/website/customize/CustomizePage';
import ConciergePage from './pages/website/concierge/ConciergePage';
import ConciergeRequestPage from './pages/website/concierge/ConciergeRequestPage';

import SettingsPage from './pages/settings/SettingsPage';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);

const router = sentryCreateBrowserRouter([
  {
    path: paths.root,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: paths.auth.signIn, element: <SignInPage />, loader: () => signedOutOnlyLoader({ message: 'You\'re already logged in.' }) },
      { path: paths.auth.signUp, element: <SignUpPage />, loader: () => signedOutOnlyLoader({ message: 'You already have an account.' }) },
      { path: paths.auth.signOut, element: <SignOutPage />, loader: signedInOnlyLoader },
      { path: paths.auth.forgotPassword, element: <ForgotPasswordPage /> },
      { path: paths.auth.resetPassword, element: <ResetPasswordPage /> },
      { path: paths.auth.sendConfirmation, element: <SendConfirmationLinkPage /> },
      { path: paths.auth.confirm, element: <ConfirmPage /> },
      { path: paths.auth.notConfirmed, element: <NotConfirmedPage />, loader: notConfirmedPageLoader },
      { path: paths.auth.deleteAccount, element: <DeleteAccountPage />, loader: signedInOnlyLoader },

      { path: paths.onboarding.selectPlan, element: <SelectPlanPage />, loader: onboardingPageLoader },
      { path: paths.onboarding.stripeCallback, element: <StripeCallbackPage />, loader: onboardingPageLoader },
      { path: paths.onboarding.bookingProvider, element: <BookingProviderPage />, loader: onboardingPageLoader },
      { path: paths.onboarding.businessInformation, element: <BusinessInformationPage />, loader: onboardingPageLoader },

      { path: paths.bookingProvider.connect, element: <ConnectBookingProviderPage />, loader: connectBookingProviderPageLoader },
      { path: paths.bookingProvider.disconnect, element: <DisconnectBookingProviderPage />, loader: () => completeLoader() },

      { path: paths.subscription.trialEnded, element: <TrialEndedPage />, loader: trialEndedPageLoader },

      { path: paths.dashboard, element: <DashboardPage />, loader: () => completeLoader() },

      { path: paths.services.index, element: <ServicesPage />, loader: () => completeLoader() },

      { path: paths.website.pages.index, element: <ComingSoonPage />, loader: () => completeLoader({ requiresPro: true }) },
      { path: paths.website.codeEditor, element: <CodeEditorPage />, loader: () => completeLoader({ requiresPro: true }) },
      { path: paths.website.customize, element: <CustomizePage />, loader: () => completeLoader() },
      { path: paths.website.concierge.index, element: <ConciergePage />, loader: () => completeLoader() },
      { path: `${paths.website.concierge.index}/:id`, element: <ConciergeRequestPage />, loader: () => completeLoader() },

      { path: paths.settings, element: <SettingsPage />, loader: () => completeLoader() },
    ],
  },
]);

function App() {
  return (
    <ApolloProvider client={graphqlClient}>
      <Toaster />

      <Helmet defaultTitle="MyPrices" titleTemplate="%s • MyPrices" />
      <RouterProvider
        router={router}
        fallbackElement={<Loading className="h-full" />}
      />
    </ApolloProvider>
  );
}

export default App;
