export interface OpenPeriod {
  __typename: 'OpenPeriod';
  day: Day;
  start: string;
  startFormatted: string;
  end: string;
  endFormatted: string;
}

export const DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] as const;
export type Day = typeof DAYS[number];
