import React from 'react';

import { DndContext, DndContextProps, MouseSensor, TouchSensor, closestCenter, useSensors, useSensor } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, SortingStrategy, verticalListSortingStrategy } from '@dnd-kit/sortable';

interface Props extends DndContextProps {
  items: string[];
  strategy?: SortingStrategy;
}

export default function SortableList({ items, strategy = verticalListSortingStrategy, ...props }: Props) {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      {...props}
    >
      <SortableContext
        items={items}
        strategy={strategy}
      >
        {props.children}
      </SortableContext>
    </DndContext>
  );
}
