const paths = {
  root: '/',
  auth: {
    signIn: '/login',
    signUp: '/signup',
    signOut: '/logout',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    sendConfirmation: '/send-confirmation',
    confirm: '/confirm',
    notConfirmed: '/not-confirmed',
    deleteAccount: '/delete-account',
  },
  onboarding: {
    selectPlan: '/onboarding/plan',
    stripeCallback: '/onboarding/stripe-callback',
    bookingProvider: '/onboarding/booking',
    businessInformation: '/onboarding/info',
  },
  subscription: {
    trialEnded: '/subscription/trial-ended',
  },
  dashboard: '/dashboard',
  bookingProvider: {
    connect: '/booking-provider/connect',
    disconnect: '/booking-provider/disconnect',
  },
  services: {
    index: '/prices',
  },
  website: {
    pages: {
      index: '/website/pages',
    },
    codeEditor: '/website/code',
    customize: '/website/customize',
    concierge: {
      index: '/website/concierge',
      show: (id: string) => `/website/concierge/${id}`,
    },
  },
  settings: '/settings',
};

export default paths;
