import client from '../../graphql/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../graphql/entities/query';

import { redirect } from 'react-router-dom';
import paths from '../../../paths';

import dayjs from 'dayjs';

export const confirmedOnlyLoader = async (): Promise<Response | null> => {
  const { data } = await client.query<Query>({ query: GET_INITIAL_DATA });

  // Redirect to "not confirmed" error page if account not confirmed and past deadline.
  if (!data.viewer!.confirmed && dayjs().isAfter(data.viewer!.confirmationDeadline)) {
    return redirect(paths.auth.notConfirmed);
  }

  return null;
};

export default confirmedOnlyLoader;
