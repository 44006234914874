import React from 'react';

import { Service } from '../../../lib/graphql/entities/service';

import SortableCell from '../../../components/SortableCell';

import { faClock } from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../../components/Icon';

interface Props {
  service: Service;
  onClick: () => void;
  sortEnabled?: boolean;
}

export default function ServiceCell({ service, onClick, sortEnabled }: Props) {
  return (
    <SortableCell sortId={service.id} sortEnabled={sortEnabled}>
      <div
        className="service-cell block hover:bg-gray-50"
        onClick={() => {
          if (sortEnabled) { return; }

          if (service.provider) {
            return window.open(service.providerUrl!, '_blank')!.focus();
          }

          onClick();
        }}
        data-id={service.id}
        data-testid="service-cell"
      >
        <div className="flex items-center justify-between px-4 py-4 sm:px-6">
          <div>
            <p className="text-sm sm:text-base font-medium">{service.name}</p>
            <p className="mt-2 flex items-center text-xs sm:text-sm text-gray-500">
              <Icon icon={faClock} className="mr-1.5 mb-0.5 h-4 w-4 flex-shrink-0 text-gray-400" />
              {service.durationFormatted}
            </p>
          </div>

          <div className="flex flex-col justify-center items-end">
            <div className="text-sm font-medium text-green-600">
              {service.priceFormatted}
            </div>

            {service.provider === 'SQUARE' && (
              <div className="mt-2 flex items-center">
                <img
                  className="w-5 h-5"
                  src={require('../../../assets/images/icons/square.png')}
                  alt=""
                />

                <span className="ml-1 text-sm text-gray-500">Square</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </SortableCell>
  );
}
