import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  SEND_CONFIRMATION_LINK,
  Data as SendConfirmationLinkData,
  Variables as SendConfirmationLinkVariables,
} from '../../../../../lib/graphql/mutations/auth/send-confirmation-link';

import toast from 'react-hot-toast';
import { Dialog } from '@headlessui/react';
import TaskCell from '../TaskCell';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';

interface Props {
  email: string;
  completed: boolean;
}

export default function ConfirmEmail({ email, completed }: Props) {
  const [open, setOpen] = useState(false);
  const [sendConfirmationLink, { loading: resending }] = useMutation<SendConfirmationLinkData, SendConfirmationLinkVariables>(SEND_CONFIRMATION_LINK, {
    variables: { input: { email } },
    update: () => {
      toast.success('We sent a new confirmation link!');
      setOpen(false);
    },
  });

  return (
    <React.Fragment>
      <TaskCell
        title="Confirm your email address"
        text="Confirming your email helps us better protect your account."
        completed={completed}
        onClick={() => setOpen(true)}
        data-testid="confirm-email-task-cell"
      />

      <Modal className="py-8" open={open} onClose={() => setOpen(false)}>
        <Dialog.Title className="text-center text-lg font-medium leading-6 text-gray-900">
          Check your email inbox
        </Dialog.Title>

        <Dialog.Description className="text-center text-sm text-gray-500 mt-2">
          We sent you an email with a link to confirm your email address. Try searching for "MyPrices".
        </Dialog.Description>

        <div className="mt-4 flex gap-x-2">
          <Button
            className="flex-1"
            color="white"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>

          <Button
            className="flex-1"
            onClick={() => sendConfirmationLink()}
            loading={resending}
            disabled={resending}
          >
            Resend email
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
}
