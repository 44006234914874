import React from 'react';

import SlideOver, { Props as SlideOverProps } from './SlideOver';

import classNames from 'classnames';

export interface Props extends SlideOverProps {
  onSubmit: () => void;
  children: React.ReactNode;
  buttons: React.ReactNode;
  buttonsClassName?: string;
}

export default function SlideOverForm({ onSubmit, children, buttons, buttonsClassName, ...props }: Props) {
  return (
    <SlideOver {...props}>
      <form onSubmit={onSubmit} className="flex-1 flex flex-col divide-y divide-gray-200 overflow-hidden">
        <div className="flex-1 py-4 sm:py-6 px-4 sm:px-6 overflow-auto">
          {children}
        </div>

        <div className={classNames('shrink-0 px-4 py-4 flex space-x-2', buttonsClassName)}>
          {buttons}
        </div>
      </form>
    </SlideOver>
  );
}
