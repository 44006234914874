import React from 'react';

import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import paths from '../paths';

import { Helmet } from 'react-helmet';
import Logo from '../components/layout/Logo';

import { Crisp } from 'crisp-sdk-web';

export default function ErrorPage() {
  const error = useRouteError();
  const notFoundError = isRouteErrorResponse(error) && error.status === 404;

  return (
    <React.Fragment>
      <Helmet>
        <title>{notFoundError ? 'Not found' : 'Server error'}</title>
      </Helmet>

      <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <Logo />
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-black" data-testid="status-code">{notFoundError ? '404' : '500'}</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl" data-testid="title">
                {notFoundError ? 'Page not found.' : 'Something went wrong.'}
              </h1>
              <p className="mt-2 text-base text-gray-500" data-testid="description">
                {notFoundError ? 'Sorry, we couldn\'t find the page you\'re looking for.' : 'Sorry, we\'ll investigate what caused this error as soon as possible.'}
              </p>
              <div className="mt-6">
                <Link to={paths.root} className="text-base font-medium text-black hover:text-neutral-900">
                  Go back home
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
        <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <button
              onClick={() => Crisp.chat.open()}
              className="text-sm font-medium text-gray-500 hover:text-gray-600"
            >
              Contact support
            </button>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <a href="https://status.myprices.io" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Status
            </a>
          </nav>
        </footer>
      </div>
    </React.Fragment>
  );
}
