import { ConciergeRequest } from '../entities/concierge-request';

export default class ConciergeRequestDecorator {
  private request: ConciergeRequest;

  constructor(request: ConciergeRequest) {
    this.request = request;
  }

  get status() {
    return ConciergeRequestDecorator.status(this.request.status);
  }

  get referenceNumber() {
    return ConciergeRequestDecorator.referenceNumber(this.request.referenceNumber);
  }

  static status(status: ConciergeRequest['status']) {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'IN_PROGRESS':
        return 'In progress';
      case 'COMPLETED':
        return 'Completed';
      case 'CANCELED':
        return 'Canceled';
    }
  }

  static referenceNumber(referenceNumber: number) {
    return `#${referenceNumber}`;
  }
}
