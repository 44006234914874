import { gql } from '@apollo/client';
import { USER_FIELDS } from '../../fragments/user';
import { User, AuthenticationProvider } from '../../entities/user';

export const SIGN_UP_OAUTH = gql`
  ${USER_FIELDS}

  mutation SignUpOAuth($input: AuthSignUpOAuthInput!) {
    authSignUpOAuth(input: $input) {
      authenticationToken
      user {
        ...UserFields
      }
    }
  }
`;

export interface Variables {
  input: {
    provider: AuthenticationProvider;
    code: string;
    timeZone: string;
    agreesToTerms: boolean;
  };
}

export interface Data {
  authSignUpOAuth: {
    authenticationToken: string;
    user: User;
  };
}
