import { User, AuthenticationProvider } from '../entities/user';
import { BookingProvider } from '../entities/booking-provider';

export default class UserDecorator {
  private user: User;

  constructor(user: User) {
    this.user = user;
  }

  get authenticationProvider() {
    return UserDecorator.authenticationProvider(this.user.authenticationProvider!);
  }

  get bookingProvider() {
    return UserDecorator.bookingProvider(this.user.bookingProvider!);
  }

  static authenticationProvider(provider: AuthenticationProvider) {
    switch (provider) {
      case 'GOOGLE':
        return 'Google';
    }
  }

  static bookingProvider(provider: BookingProvider) {
    switch (provider) {
      case 'SQUARE':
        return 'Square';
    }
  }
}
