import React from 'react';

import FieldContainer, { Props as FieldContainerProps } from './FieldContainer';

import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import Icon, { Props as IconProps } from '../Icon';

import classNames from 'classnames';

export interface Props extends FieldContainerProps, React.ComponentPropsWithoutRef<'input'> {
  icon?: IconProps['icon'];

  leftAddon?: React.ReactNode;
  leftSelectAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;
  rightSelectAddon?: React.ReactNode;
  inputSize?: 'base' | 'lg';
}

const InputField = React.forwardRef<HTMLInputElement, Props>(({
  label,
  icon,
  inputSize = 'base',
  leftAddon,
  leftSelectAddon,
  rightAddon,
  rightSelectAddon,
  error,
  help,
  ...inputProps
}, ref) => {
  const id = inputProps.id || inputProps.name;

  return (
    <FieldContainer id={id} label={label} error={error} help={help} data-testid="input-field">
      <div className="relative rounded-md">
        <Addon side="left" className={classNames('space-x-3', { '!pl-0': leftSelectAddon })}>
          {leftAddon && <div>{leftAddon}</div>}
          {leftSelectAddon}

          {icon && (
            <Icon
              icon={icon}
              className="h-[18px] w-[18px] text-gray-400"
            />
          )}
        </Addon>

        <input
          ref={ref}
          id={id}
          {...error && { 'aria-invalid': true, 'aria-errormessage': id ? `${id}-error` : undefined }}
          {...help && { 'aria-describedby': id ? `${id}-description` : undefined }}
          {...inputProps}
          placeholder={inputProps.disabled ? label : inputProps.placeholder}
          className={classNames('appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:ring-black focus:border-black disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400', {
            'px-3 pt-2 pb-2 sm:text-sm': inputSize === 'base',
            'px-4 pt-3 pb-3': inputSize === 'lg',
            'pl-10': icon != null,
            'pr-10 border-red-300 text-red-900 placeholder:text-red-300 focus:ring-red-500 focus:border-red-500': error != null,
            'flex pb-1.5': inputProps.type === 'datetime-local', // Fixes weird bottom padding when using datetime-local
          }, inputProps.className)}
        />

        <Addon side="right" className={classNames('space-x-3', { '!pr-0': rightSelectAddon })}>
          {rightAddon && <div>{rightAddon}</div>}

          {(error && !rightSelectAddon) && <Icon icon={faExclamationCircle} className="h-5 w-5 text-red-500" />}

          {rightSelectAddon}
        </Addon>
      </div>
    </FieldContainer>
  );
});

interface AddonProps {
  side: 'left' | 'right';
  children: React.ReactNode;
  className?: string;
}

function Addon({ side, children, className }: AddonProps) {
  return (
    <div
      className={classNames(
        'absolute inset-y-0 flex items-center',
        {
          'left-0 pl-3': side === 'left',
          'right-0 pr-3': side === 'right',
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

interface SelectAddonProps extends React.ComponentPropsWithoutRef<'select'> {
  label: string;
  error?: React.ReactNode;
  children?: React.ReactNode;
}

export const SelectAddon = React.forwardRef<HTMLSelectElement, SelectAddonProps>(({ label, error, children, ...props }, ref) => {
  const id = props.id || props.name;

  return (
    <select
      ref={ref}
      id={id}
      {...props}
      className={classNames(
        'h-full py-0 border-transparent bg-transparent text-gray-500 focus:ring-black focus:border-black rounded-md pl-4 pr-8',
        { 'focus:ring-red-500 focus:border-red-500': error != null },
        props.className,
      )}
      aria-label={label}
    >
      {children}
    </select>
  );
});

export default InputField;
